import { default as jdjyfxHEtPjLNgYKMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/chengshi/jdjyfx.vue?macro=true";
import { default as ndjyfx9tUO7UkgvLMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/chengshi/ndjyfx.vue?macro=true";
import { default as ndjyycHh0d4ujIdSMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/chengshi/ndjyyc.vue?macro=true";
import { default as pgyzwdWH5lhh8tbMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/chengshi/pgyzw.vue?macro=true";
import { default as ydjyfxTkjof9zKaQMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/chengshi/ydjyfx.vue?macro=true";
import { default as companyListVOQkevXwErMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/hangye/companyList.ts?macro=true";
import { default as hmnbjc2HNOPu0RMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/hangye/hmnb.vue?macro=true";
import { default as jdesgGrrfGMgtRUMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/hangye/jdesg.vue?macro=true";
import { default as jgjtjdcb_45xqff4tmvBOitMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/hangye/jgjtjdcb-xq.vue?macro=true";
import { default as jgjtjdcbd2Cxl0rMcLMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/hangye/jgjtjdcb.vue?macro=true";
import { default as jgjtReportsesrd6cXWBOMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/hangye/jgjtReports.ts?macro=true";
import { default as wlbtjbgUwxgktalOBMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/hangye/wlbtjbg.vue?macro=true";
import { default as _91hotelName_938HHevIF8bqMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/hotel-manage/[hotelName].vue?macro=true";
import { default as hotel_45viewerGIBJc5Xj6vMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/hotel-viewer.vue?macro=true";
import { default as jcsjtbycbkSp2zTaMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/jiudian/jcsjtb.vue?macro=true";
import { default as jdjyfxwoKgy9GrEpMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/jiudian/jdjyfx.vue?macro=true";
import { default as ndjyfxz0WlnHnWE9Meta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/jiudian/ndjyfx.vue?macro=true";
import { default as ydjyfxFLb6zEpO2CMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/jiudian/ydjyfx.vue?macro=true";
import { default as ydsjtbt5PcYwiRNtMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/jiudian/ydsjtb.vue?macro=true";
import { default as loginC2qbgOTYwSMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/login.vue?macro=true";
import { default as afterLoginEm6McRdwfIMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/loginUtils/afterLogin.ts?macro=true";
import { default as getAuthorizationswsQwcuUzxyMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/loginUtils/afterLoginUtils/getAuthorizations.ts?macro=true";
import { default as sendSMSpP9RCBe3DpMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/loginUtils/sendSMS.ts?macro=true";
import { default as SMSLoginEmM5aQGNarMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/loginUtils/SMSLogin.ts?macro=true";
import { default as pdf_45previewWiuW2TjpLtMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/pdf-preview.vue?macro=true";
import { default as report_45viewerZYQi6khBcsMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/report-viewer.vue?macro=true";
import { default as _filePathUWzH3VuF7JMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/_filePath.vue?macro=true";
import { default as _91sectionName_9367JFyjFa89Meta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/[sectionName].vue?macro=true";
import { default as displayProjects8306vcxkH6Meta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/sectionUtils/displayProjects.ts?macro=true";
import { default as getAdFilesbQrysAY24fMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/sectionUtils/getAdFiles.ts?macro=true";
import { default as getCategory1Optionsk7bETYe4opMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/sectionUtils/getCategory1Options.ts?macro=true";
import { default as getPageContent09xDGOCWBhMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/sectionUtils/getPageContent.ts?macro=true";
import { default as getPageContentOverridecVhmnwftShMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/sectionUtils/getPageContentOverride.ts?macro=true";
import { default as huameiAnnualsBNuSMygyCpMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/sectionUtils/huameiAnnuals.ts?macro=true";
import { default as processLeftMenuQg6F40EszHMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/sectionUtils/processLeftMenu.ts?macro=true";
import { default as brandsKoMuOHSpAOMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/sectionUtils/utils/brands.ts?macro=true";
import { default as citiesB5ebT9GIqeMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/sectionUtils/utils/cities.ts?macro=true";
import { default as jdjyfx5GgmigYnvfMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/shengfen/jdjyfx.vue?macro=true";
import { default as ndjyfxPI20NgrCyoMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/shengfen/ndjyfx.vue?macro=true";
import { default as pgyzw09dZDEngbTMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/shengfen/pgyzw.vue?macro=true";
import { default as ydjyfxF6cKp37nHgMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/shengfen/ydjyfx.vue?macro=true";
import { default as indexNcg7FUcI9WMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/start/index.vue?macro=true";
import { default as siteConfigIwHTTPl4NiMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/start/startUtils/siteConfig.ts?macro=true";
import { default as _91userId_93jZ2UWFEiw3Meta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/user-manage/[userId].vue?macro=true";
import { default as bgsckdxyRKSlMgMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/xiehui/bgsc.vue?macro=true";
import { default as bgylzkPUHJcnKgMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/xiehui/bgyl.vue?macro=true";
import { default as report_45taskGi6M3x7hOzMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/xiehui/report-task.vue?macro=true";
import { default as sampleReports7g0gRab8hBMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/xiehui/sampleReports.ts?macro=true";
import { default as sampleTaskskn3F0qjvuvMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/xiehui/sampleTasks.ts?macro=true";
import { default as ydsjsh8Q1jCaFjIiMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/xiehui/ydsjsh.vue?macro=true";
import { default as jdyhgl2yxBejlBv1Meta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/xitong/jdyhgl.vue?macro=true";
import { default as qtyhglgz4d6jNxZBMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/xitong/qtyhgl.vue?macro=true";
import { default as xtszpxQUug63agMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/xitong/xtsz.vue?macro=true";
import { default as yhgl8L3yQL7KUXMeta } from "/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/xitong/yhgl.vue?macro=true";
export default [
  {
    name: jdjyfxHEtPjLNgYKMeta?.name ?? "chengshi-jdjyfx",
    path: jdjyfxHEtPjLNgYKMeta?.path ?? "/chengshi/jdjyfx",
    meta: jdjyfxHEtPjLNgYKMeta || {},
    alias: jdjyfxHEtPjLNgYKMeta?.alias || [],
    redirect: jdjyfxHEtPjLNgYKMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/chengshi/jdjyfx.vue").then(m => m.default || m)
  },
  {
    name: ndjyfx9tUO7UkgvLMeta?.name ?? "chengshi-ndjyfx",
    path: ndjyfx9tUO7UkgvLMeta?.path ?? "/chengshi/ndjyfx",
    meta: ndjyfx9tUO7UkgvLMeta || {},
    alias: ndjyfx9tUO7UkgvLMeta?.alias || [],
    redirect: ndjyfx9tUO7UkgvLMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/chengshi/ndjyfx.vue").then(m => m.default || m)
  },
  {
    name: ndjyycHh0d4ujIdSMeta?.name ?? "chengshi-ndjyyc",
    path: ndjyycHh0d4ujIdSMeta?.path ?? "/chengshi/ndjyyc",
    meta: ndjyycHh0d4ujIdSMeta || {},
    alias: ndjyycHh0d4ujIdSMeta?.alias || [],
    redirect: ndjyycHh0d4ujIdSMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/chengshi/ndjyyc.vue").then(m => m.default || m)
  },
  {
    name: pgyzwdWH5lhh8tbMeta?.name ?? "chengshi-pgyzw",
    path: pgyzwdWH5lhh8tbMeta?.path ?? "/chengshi/pgyzw",
    meta: pgyzwdWH5lhh8tbMeta || {},
    alias: pgyzwdWH5lhh8tbMeta?.alias || [],
    redirect: pgyzwdWH5lhh8tbMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/chengshi/pgyzw.vue").then(m => m.default || m)
  },
  {
    name: ydjyfxTkjof9zKaQMeta?.name ?? "chengshi-ydjyfx",
    path: ydjyfxTkjof9zKaQMeta?.path ?? "/chengshi/ydjyfx",
    meta: ydjyfxTkjof9zKaQMeta || {},
    alias: ydjyfxTkjof9zKaQMeta?.alias || [],
    redirect: ydjyfxTkjof9zKaQMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/chengshi/ydjyfx.vue").then(m => m.default || m)
  },
  {
    name: companyListVOQkevXwErMeta?.name ?? "hangye-companyList",
    path: companyListVOQkevXwErMeta?.path ?? "/hangye/companyList",
    meta: companyListVOQkevXwErMeta || {},
    alias: companyListVOQkevXwErMeta?.alias || [],
    redirect: companyListVOQkevXwErMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/hangye/companyList.ts").then(m => m.default || m)
  },
  {
    name: hmnbjc2HNOPu0RMeta?.name ?? "hangye-hmnb",
    path: hmnbjc2HNOPu0RMeta?.path ?? "/hangye/hmnb",
    meta: hmnbjc2HNOPu0RMeta || {},
    alias: hmnbjc2HNOPu0RMeta?.alias || [],
    redirect: hmnbjc2HNOPu0RMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/hangye/hmnb.vue").then(m => m.default || m)
  },
  {
    name: jdesgGrrfGMgtRUMeta?.name ?? "hangye-jdesg",
    path: jdesgGrrfGMgtRUMeta?.path ?? "/hangye/jdesg",
    meta: jdesgGrrfGMgtRUMeta || {},
    alias: jdesgGrrfGMgtRUMeta?.alias || [],
    redirect: jdesgGrrfGMgtRUMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/hangye/jdesg.vue").then(m => m.default || m)
  },
  {
    name: jgjtjdcb_45xqff4tmvBOitMeta?.name ?? "hangye-jgjtjdcb-xq",
    path: jgjtjdcb_45xqff4tmvBOitMeta?.path ?? "/hangye/jgjtjdcb-xq",
    meta: jgjtjdcb_45xqff4tmvBOitMeta || {},
    alias: jgjtjdcb_45xqff4tmvBOitMeta?.alias || [],
    redirect: jgjtjdcb_45xqff4tmvBOitMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/hangye/jgjtjdcb-xq.vue").then(m => m.default || m)
  },
  {
    name: jgjtjdcbd2Cxl0rMcLMeta?.name ?? "hangye-jgjtjdcb",
    path: jgjtjdcbd2Cxl0rMcLMeta?.path ?? "/hangye/jgjtjdcb",
    meta: jgjtjdcbd2Cxl0rMcLMeta || {},
    alias: jgjtjdcbd2Cxl0rMcLMeta?.alias || [],
    redirect: jgjtjdcbd2Cxl0rMcLMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/hangye/jgjtjdcb.vue").then(m => m.default || m)
  },
  {
    name: jgjtReportsesrd6cXWBOMeta?.name ?? "hangye-jgjtReports",
    path: jgjtReportsesrd6cXWBOMeta?.path ?? "/hangye/jgjtReports",
    meta: jgjtReportsesrd6cXWBOMeta || {},
    alias: jgjtReportsesrd6cXWBOMeta?.alias || [],
    redirect: jgjtReportsesrd6cXWBOMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/hangye/jgjtReports.ts").then(m => m.default || m)
  },
  {
    name: wlbtjbgUwxgktalOBMeta?.name ?? "hangye-wlbtjbg",
    path: wlbtjbgUwxgktalOBMeta?.path ?? "/hangye/wlbtjbg",
    meta: wlbtjbgUwxgktalOBMeta || {},
    alias: wlbtjbgUwxgktalOBMeta?.alias || [],
    redirect: wlbtjbgUwxgktalOBMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/hangye/wlbtjbg.vue").then(m => m.default || m)
  },
  {
    name: _91hotelName_938HHevIF8bqMeta?.name ?? "hotel-manage-hotelName",
    path: _91hotelName_938HHevIF8bqMeta?.path ?? "/hotel-manage/:hotelName()",
    meta: _91hotelName_938HHevIF8bqMeta || {},
    alias: _91hotelName_938HHevIF8bqMeta?.alias || [],
    redirect: _91hotelName_938HHevIF8bqMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/hotel-manage/[hotelName].vue").then(m => m.default || m)
  },
  {
    name: hotel_45viewerGIBJc5Xj6vMeta?.name ?? "hotel-viewer",
    path: hotel_45viewerGIBJc5Xj6vMeta?.path ?? "/hotel-viewer",
    meta: hotel_45viewerGIBJc5Xj6vMeta || {},
    alias: hotel_45viewerGIBJc5Xj6vMeta?.alias || [],
    redirect: hotel_45viewerGIBJc5Xj6vMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/hotel-viewer.vue").then(m => m.default || m)
  },
  {
    name: jcsjtbycbkSp2zTaMeta?.name ?? "jiudian-jcsjtb",
    path: jcsjtbycbkSp2zTaMeta?.path ?? "/jiudian/jcsjtb",
    meta: jcsjtbycbkSp2zTaMeta || {},
    alias: jcsjtbycbkSp2zTaMeta?.alias || [],
    redirect: jcsjtbycbkSp2zTaMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/jiudian/jcsjtb.vue").then(m => m.default || m)
  },
  {
    name: jdjyfxwoKgy9GrEpMeta?.name ?? "jiudian-jdjyfx",
    path: jdjyfxwoKgy9GrEpMeta?.path ?? "/jiudian/jdjyfx",
    meta: jdjyfxwoKgy9GrEpMeta || {},
    alias: jdjyfxwoKgy9GrEpMeta?.alias || [],
    redirect: jdjyfxwoKgy9GrEpMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/jiudian/jdjyfx.vue").then(m => m.default || m)
  },
  {
    name: ndjyfxz0WlnHnWE9Meta?.name ?? "jiudian-ndjyfx",
    path: ndjyfxz0WlnHnWE9Meta?.path ?? "/jiudian/ndjyfx",
    meta: ndjyfxz0WlnHnWE9Meta || {},
    alias: ndjyfxz0WlnHnWE9Meta?.alias || [],
    redirect: ndjyfxz0WlnHnWE9Meta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/jiudian/ndjyfx.vue").then(m => m.default || m)
  },
  {
    name: ydjyfxFLb6zEpO2CMeta?.name ?? "jiudian-ydjyfx",
    path: ydjyfxFLb6zEpO2CMeta?.path ?? "/jiudian/ydjyfx",
    meta: ydjyfxFLb6zEpO2CMeta || {},
    alias: ydjyfxFLb6zEpO2CMeta?.alias || [],
    redirect: ydjyfxFLb6zEpO2CMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/jiudian/ydjyfx.vue").then(m => m.default || m)
  },
  {
    name: ydsjtbt5PcYwiRNtMeta?.name ?? "jiudian-ydsjtb",
    path: ydsjtbt5PcYwiRNtMeta?.path ?? "/jiudian/ydsjtb",
    meta: ydsjtbt5PcYwiRNtMeta || {},
    alias: ydsjtbt5PcYwiRNtMeta?.alias || [],
    redirect: ydsjtbt5PcYwiRNtMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/jiudian/ydsjtb.vue").then(m => m.default || m)
  },
  {
    name: loginC2qbgOTYwSMeta?.name ?? "login",
    path: loginC2qbgOTYwSMeta?.path ?? "/login",
    meta: loginC2qbgOTYwSMeta || {},
    alias: loginC2qbgOTYwSMeta?.alias || [],
    redirect: loginC2qbgOTYwSMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: afterLoginEm6McRdwfIMeta?.name ?? "loginUtils-afterLogin",
    path: afterLoginEm6McRdwfIMeta?.path ?? "/loginUtils/afterLogin",
    meta: afterLoginEm6McRdwfIMeta || {},
    alias: afterLoginEm6McRdwfIMeta?.alias || [],
    redirect: afterLoginEm6McRdwfIMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/loginUtils/afterLogin.ts").then(m => m.default || m)
  },
  {
    name: getAuthorizationswsQwcuUzxyMeta?.name ?? "loginUtils-afterLoginUtils-getAuthorizations",
    path: getAuthorizationswsQwcuUzxyMeta?.path ?? "/loginUtils/afterLoginUtils/getAuthorizations",
    meta: getAuthorizationswsQwcuUzxyMeta || {},
    alias: getAuthorizationswsQwcuUzxyMeta?.alias || [],
    redirect: getAuthorizationswsQwcuUzxyMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/loginUtils/afterLoginUtils/getAuthorizations.ts").then(m => m.default || m)
  },
  {
    name: sendSMSpP9RCBe3DpMeta?.name ?? "loginUtils-sendSMS",
    path: sendSMSpP9RCBe3DpMeta?.path ?? "/loginUtils/sendSMS",
    meta: sendSMSpP9RCBe3DpMeta || {},
    alias: sendSMSpP9RCBe3DpMeta?.alias || [],
    redirect: sendSMSpP9RCBe3DpMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/loginUtils/sendSMS.ts").then(m => m.default || m)
  },
  {
    name: SMSLoginEmM5aQGNarMeta?.name ?? "loginUtils-SMSLogin",
    path: SMSLoginEmM5aQGNarMeta?.path ?? "/loginUtils/SMSLogin",
    meta: SMSLoginEmM5aQGNarMeta || {},
    alias: SMSLoginEmM5aQGNarMeta?.alias || [],
    redirect: SMSLoginEmM5aQGNarMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/loginUtils/SMSLogin.ts").then(m => m.default || m)
  },
  {
    name: pdf_45previewWiuW2TjpLtMeta?.name ?? "pdf-preview",
    path: pdf_45previewWiuW2TjpLtMeta?.path ?? "/pdf-preview",
    meta: pdf_45previewWiuW2TjpLtMeta || {},
    alias: pdf_45previewWiuW2TjpLtMeta?.alias || [],
    redirect: pdf_45previewWiuW2TjpLtMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/pdf-preview.vue").then(m => m.default || m)
  },
  {
    name: report_45viewerZYQi6khBcsMeta?.name ?? "report-viewer",
    path: report_45viewerZYQi6khBcsMeta?.path ?? "/report-viewer",
    meta: report_45viewerZYQi6khBcsMeta || {},
    alias: report_45viewerZYQi6khBcsMeta?.alias || [],
    redirect: report_45viewerZYQi6khBcsMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/report-viewer.vue").then(m => m.default || m)
  },
  {
    name: _filePathUWzH3VuF7JMeta?.name ?? "section-_filePath",
    path: _filePathUWzH3VuF7JMeta?.path ?? "/section/_filePath",
    meta: _filePathUWzH3VuF7JMeta || {},
    alias: _filePathUWzH3VuF7JMeta?.alias || [],
    redirect: _filePathUWzH3VuF7JMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/_filePath.vue").then(m => m.default || m)
  },
  {
    name: _91sectionName_9367JFyjFa89Meta?.name ?? "section-sectionName",
    path: _91sectionName_9367JFyjFa89Meta?.path ?? "/section/:sectionName()",
    meta: _91sectionName_9367JFyjFa89Meta || {},
    alias: _91sectionName_9367JFyjFa89Meta?.alias || [],
    redirect: _91sectionName_9367JFyjFa89Meta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/[sectionName].vue").then(m => m.default || m)
  },
  {
    name: displayProjects8306vcxkH6Meta?.name ?? "section-sectionUtils-displayProjects",
    path: displayProjects8306vcxkH6Meta?.path ?? "/section/sectionUtils/displayProjects",
    meta: displayProjects8306vcxkH6Meta || {},
    alias: displayProjects8306vcxkH6Meta?.alias || [],
    redirect: displayProjects8306vcxkH6Meta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/sectionUtils/displayProjects.ts").then(m => m.default || m)
  },
  {
    name: getAdFilesbQrysAY24fMeta?.name ?? "section-sectionUtils-getAdFiles",
    path: getAdFilesbQrysAY24fMeta?.path ?? "/section/sectionUtils/getAdFiles",
    meta: getAdFilesbQrysAY24fMeta || {},
    alias: getAdFilesbQrysAY24fMeta?.alias || [],
    redirect: getAdFilesbQrysAY24fMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/sectionUtils/getAdFiles.ts").then(m => m.default || m)
  },
  {
    name: getCategory1Optionsk7bETYe4opMeta?.name ?? "section-sectionUtils-getCategory1Options",
    path: getCategory1Optionsk7bETYe4opMeta?.path ?? "/section/sectionUtils/getCategory1Options",
    meta: getCategory1Optionsk7bETYe4opMeta || {},
    alias: getCategory1Optionsk7bETYe4opMeta?.alias || [],
    redirect: getCategory1Optionsk7bETYe4opMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/sectionUtils/getCategory1Options.ts").then(m => m.default || m)
  },
  {
    name: getPageContent09xDGOCWBhMeta?.name ?? "section-sectionUtils-getPageContent",
    path: getPageContent09xDGOCWBhMeta?.path ?? "/section/sectionUtils/getPageContent",
    meta: getPageContent09xDGOCWBhMeta || {},
    alias: getPageContent09xDGOCWBhMeta?.alias || [],
    redirect: getPageContent09xDGOCWBhMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/sectionUtils/getPageContent.ts").then(m => m.default || m)
  },
  {
    name: getPageContentOverridecVhmnwftShMeta?.name ?? "section-sectionUtils-getPageContentOverride",
    path: getPageContentOverridecVhmnwftShMeta?.path ?? "/section/sectionUtils/getPageContentOverride",
    meta: getPageContentOverridecVhmnwftShMeta || {},
    alias: getPageContentOverridecVhmnwftShMeta?.alias || [],
    redirect: getPageContentOverridecVhmnwftShMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/sectionUtils/getPageContentOverride.ts").then(m => m.default || m)
  },
  {
    name: huameiAnnualsBNuSMygyCpMeta?.name ?? "section-sectionUtils-huameiAnnuals",
    path: huameiAnnualsBNuSMygyCpMeta?.path ?? "/section/sectionUtils/huameiAnnuals",
    meta: huameiAnnualsBNuSMygyCpMeta || {},
    alias: huameiAnnualsBNuSMygyCpMeta?.alias || [],
    redirect: huameiAnnualsBNuSMygyCpMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/sectionUtils/huameiAnnuals.ts").then(m => m.default || m)
  },
  {
    name: processLeftMenuQg6F40EszHMeta?.name ?? "section-sectionUtils-processLeftMenu",
    path: processLeftMenuQg6F40EszHMeta?.path ?? "/section/sectionUtils/processLeftMenu",
    meta: processLeftMenuQg6F40EszHMeta || {},
    alias: processLeftMenuQg6F40EszHMeta?.alias || [],
    redirect: processLeftMenuQg6F40EszHMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/sectionUtils/processLeftMenu.ts").then(m => m.default || m)
  },
  {
    name: brandsKoMuOHSpAOMeta?.name ?? "section-sectionUtils-utils-brands",
    path: brandsKoMuOHSpAOMeta?.path ?? "/section/sectionUtils/utils/brands",
    meta: brandsKoMuOHSpAOMeta || {},
    alias: brandsKoMuOHSpAOMeta?.alias || [],
    redirect: brandsKoMuOHSpAOMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/sectionUtils/utils/brands.ts").then(m => m.default || m)
  },
  {
    name: citiesB5ebT9GIqeMeta?.name ?? "section-sectionUtils-utils-cities",
    path: citiesB5ebT9GIqeMeta?.path ?? "/section/sectionUtils/utils/cities",
    meta: citiesB5ebT9GIqeMeta || {},
    alias: citiesB5ebT9GIqeMeta?.alias || [],
    redirect: citiesB5ebT9GIqeMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/section/sectionUtils/utils/cities.ts").then(m => m.default || m)
  },
  {
    name: jdjyfx5GgmigYnvfMeta?.name ?? "shengfen-jdjyfx",
    path: jdjyfx5GgmigYnvfMeta?.path ?? "/shengfen/jdjyfx",
    meta: jdjyfx5GgmigYnvfMeta || {},
    alias: jdjyfx5GgmigYnvfMeta?.alias || [],
    redirect: jdjyfx5GgmigYnvfMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/shengfen/jdjyfx.vue").then(m => m.default || m)
  },
  {
    name: ndjyfxPI20NgrCyoMeta?.name ?? "shengfen-ndjyfx",
    path: ndjyfxPI20NgrCyoMeta?.path ?? "/shengfen/ndjyfx",
    meta: ndjyfxPI20NgrCyoMeta || {},
    alias: ndjyfxPI20NgrCyoMeta?.alias || [],
    redirect: ndjyfxPI20NgrCyoMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/shengfen/ndjyfx.vue").then(m => m.default || m)
  },
  {
    name: pgyzw09dZDEngbTMeta?.name ?? "shengfen-pgyzw",
    path: pgyzw09dZDEngbTMeta?.path ?? "/shengfen/pgyzw",
    meta: pgyzw09dZDEngbTMeta || {},
    alias: pgyzw09dZDEngbTMeta?.alias || [],
    redirect: pgyzw09dZDEngbTMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/shengfen/pgyzw.vue").then(m => m.default || m)
  },
  {
    name: ydjyfxF6cKp37nHgMeta?.name ?? "shengfen-ydjyfx",
    path: ydjyfxF6cKp37nHgMeta?.path ?? "/shengfen/ydjyfx",
    meta: ydjyfxF6cKp37nHgMeta || {},
    alias: ydjyfxF6cKp37nHgMeta?.alias || [],
    redirect: ydjyfxF6cKp37nHgMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/shengfen/ydjyfx.vue").then(m => m.default || m)
  },
  {
    name: indexNcg7FUcI9WMeta?.name ?? "start",
    path: indexNcg7FUcI9WMeta?.path ?? "/start",
    meta: indexNcg7FUcI9WMeta || {},
    alias: indexNcg7FUcI9WMeta?.alias || [],
    redirect: indexNcg7FUcI9WMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/start/index.vue").then(m => m.default || m)
  },
  {
    name: siteConfigIwHTTPl4NiMeta?.name ?? "start-startUtils-siteConfig",
    path: siteConfigIwHTTPl4NiMeta?.path ?? "/start/startUtils/siteConfig",
    meta: siteConfigIwHTTPl4NiMeta || {},
    alias: siteConfigIwHTTPl4NiMeta?.alias || [],
    redirect: siteConfigIwHTTPl4NiMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/start/startUtils/siteConfig.ts").then(m => m.default || m)
  },
  {
    name: _91userId_93jZ2UWFEiw3Meta?.name ?? "user-manage-userId",
    path: _91userId_93jZ2UWFEiw3Meta?.path ?? "/user-manage/:userId()",
    meta: _91userId_93jZ2UWFEiw3Meta || {},
    alias: _91userId_93jZ2UWFEiw3Meta?.alias || [],
    redirect: _91userId_93jZ2UWFEiw3Meta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/user-manage/[userId].vue").then(m => m.default || m)
  },
  {
    name: bgsckdxyRKSlMgMeta?.name ?? "xiehui-bgsc",
    path: bgsckdxyRKSlMgMeta?.path ?? "/xiehui/bgsc",
    meta: bgsckdxyRKSlMgMeta || {},
    alias: bgsckdxyRKSlMgMeta?.alias || [],
    redirect: bgsckdxyRKSlMgMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/xiehui/bgsc.vue").then(m => m.default || m)
  },
  {
    name: bgylzkPUHJcnKgMeta?.name ?? "xiehui-bgyl",
    path: bgylzkPUHJcnKgMeta?.path ?? "/xiehui/bgyl",
    meta: bgylzkPUHJcnKgMeta || {},
    alias: bgylzkPUHJcnKgMeta?.alias || [],
    redirect: bgylzkPUHJcnKgMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/xiehui/bgyl.vue").then(m => m.default || m)
  },
  {
    name: report_45taskGi6M3x7hOzMeta?.name ?? "xiehui-report-task",
    path: report_45taskGi6M3x7hOzMeta?.path ?? "/xiehui/report-task",
    meta: report_45taskGi6M3x7hOzMeta || {},
    alias: report_45taskGi6M3x7hOzMeta?.alias || [],
    redirect: report_45taskGi6M3x7hOzMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/xiehui/report-task.vue").then(m => m.default || m)
  },
  {
    name: sampleReports7g0gRab8hBMeta?.name ?? "xiehui-sampleReports",
    path: sampleReports7g0gRab8hBMeta?.path ?? "/xiehui/sampleReports",
    meta: sampleReports7g0gRab8hBMeta || {},
    alias: sampleReports7g0gRab8hBMeta?.alias || [],
    redirect: sampleReports7g0gRab8hBMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/xiehui/sampleReports.ts").then(m => m.default || m)
  },
  {
    name: sampleTaskskn3F0qjvuvMeta?.name ?? "xiehui-sampleTasks",
    path: sampleTaskskn3F0qjvuvMeta?.path ?? "/xiehui/sampleTasks",
    meta: sampleTaskskn3F0qjvuvMeta || {},
    alias: sampleTaskskn3F0qjvuvMeta?.alias || [],
    redirect: sampleTaskskn3F0qjvuvMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/xiehui/sampleTasks.ts").then(m => m.default || m)
  },
  {
    name: ydsjsh8Q1jCaFjIiMeta?.name ?? "xiehui-ydsjsh",
    path: ydsjsh8Q1jCaFjIiMeta?.path ?? "/xiehui/ydsjsh",
    meta: ydsjsh8Q1jCaFjIiMeta || {},
    alias: ydsjsh8Q1jCaFjIiMeta?.alias || [],
    redirect: ydsjsh8Q1jCaFjIiMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/xiehui/ydsjsh.vue").then(m => m.default || m)
  },
  {
    name: jdyhgl2yxBejlBv1Meta?.name ?? "xitong-jdyhgl",
    path: jdyhgl2yxBejlBv1Meta?.path ?? "/xitong/jdyhgl",
    meta: jdyhgl2yxBejlBv1Meta || {},
    alias: jdyhgl2yxBejlBv1Meta?.alias || [],
    redirect: jdyhgl2yxBejlBv1Meta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/xitong/jdyhgl.vue").then(m => m.default || m)
  },
  {
    name: qtyhglgz4d6jNxZBMeta?.name ?? "xitong-qtyhgl",
    path: qtyhglgz4d6jNxZBMeta?.path ?? "/xitong/qtyhgl",
    meta: qtyhglgz4d6jNxZBMeta || {},
    alias: qtyhglgz4d6jNxZBMeta?.alias || [],
    redirect: qtyhglgz4d6jNxZBMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/xitong/qtyhgl.vue").then(m => m.default || m)
  },
  {
    name: xtszpxQUug63agMeta?.name ?? "xitong-xtsz",
    path: xtszpxQUug63agMeta?.path ?? "/xitong/xtsz",
    meta: xtszpxQUug63agMeta || {},
    alias: xtszpxQUug63agMeta?.alias || [],
    redirect: xtszpxQUug63agMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/xitong/xtsz.vue").then(m => m.default || m)
  },
  {
    name: yhgl8L3yQL7KUXMeta?.name ?? "xitong-yhgl",
    path: yhgl8L3yQL7KUXMeta?.path ?? "/xitong/yhgl",
    meta: yhgl8L3yQL7KUXMeta || {},
    alias: yhgl8L3yQL7KUXMeta?.alias || [],
    redirect: yhgl8L3yQL7KUXMeta?.redirect,
    component: () => import("/Users/tian/Desktop/tt/work/DEV/datawang/frontend/pages/xitong/yhgl.vue").then(m => m.default || m)
  }
]