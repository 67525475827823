export default [
    {
        "1.报告名称":"长沙市季度经营分析报告2024Q1",
        "2.生成日期":"2024-11-15",
        "3.报告分类":"季度经营分析报告",
        "4.数据范围":"长沙市",
        "4.操作":"查看报告",
        "【操作】（新窗口）":"http://oss.datawang.com/examples/%E9%87%9C%E5%BA%95%E6%8A%BD%E8%96%AA%E6%8A%A5%E5%91%8A%E6%A8%A1%E6%9D%BF1114/%E9%95%BF%E6%B2%99%E5%B8%82%E9%85%92%E5%BA%97%E5%AD%A3%E5%BA%A6%E7%BB%8F%E8%90%A5%E5%88%86%E6%9E%90%E6%8A%A5%E5%91%8A.pdf",
    },
    {
        "1.报告名称":"株洲市季度经营分析报告2024Q1",
        "2.生成日期":"2024-11-15",
        "3.报告分类":"季度经营分析报告",
        "4.数据范围":"株洲市",
        "4.操作":"查看报告",
        "【操作】（新窗口）":"http://oss.datawang.com/examples/%E9%87%9C%E5%BA%95%E6%8A%BD%E8%96%AA%E6%8A%A5%E5%91%8A%E6%A8%A1%E6%9D%BF1114/%E9%95%BF%E6%B2%99%E5%B8%82%E9%85%92%E5%BA%97%E5%AD%A3%E5%BA%A6%E7%BB%8F%E8%90%A5%E5%88%86%E6%9E%90%E6%8A%A5%E5%91%8A.pdf",
    },
    {
        "1.报告名称":"湘潭市季度经营分析报告2024Q1",
        "2.生成日期":"2024-11-15",
        "3.报告分类":"季度经营分析报告",
        "4.数据范围":"湘潭市",
        "4.操作":"查看报告",
        "【操作】（新窗口）":"http://oss.datawang.com/examples/%E9%87%9C%E5%BA%95%E6%8A%BD%E8%96%AA%E6%8A%A5%E5%91%8A%E6%A8%A1%E6%9D%BF1114/%E9%95%BF%E6%B2%99%E5%B8%82%E9%85%92%E5%BA%97%E5%AD%A3%E5%BA%A6%E7%BB%8F%E8%90%A5%E5%88%86%E6%9E%90%E6%8A%A5%E5%91%8A.pdf",
    },
    {
        "1.报告名称":"衡阳市季度经营分析报告2024Q1",
        "2.生成日期":"2024-11-15",
        "3.报告分类":"季度经营分析报告",
        "4.数据范围":"衡阳市",
        "4.操作":"查看报告",
        "【操作】（新窗口）":"http://oss.datawang.com/examples/%E9%87%9C%E5%BA%95%E6%8A%BD%E8%96%AA%E6%8A%A5%E5%91%8A%E6%A8%A1%E6%9D%BF1114/%E9%95%BF%E6%B2%99%E5%B8%82%E9%85%92%E5%BA%97%E5%AD%A3%E5%BA%A6%E7%BB%8F%E8%90%A5%E5%88%86%E6%9E%90%E6%8A%A5%E5%91%8A.pdf",
    },
    {
        "1.报告名称":"邵阳市季度经营分析报告2024Q1",
        "2.生成日期":"2024-11-15",
        "3.报告分类":"季度经营分析报告",
        "4.数据范围":"邵阳市",
        "4.操作":"查看报告",
        "【操作】（新窗口）":"http://oss.datawang.com/examples/%E9%87%9C%E5%BA%95%E6%8A%BD%E8%96%AA%E6%8A%A5%E5%91%8A%E6%A8%A1%E6%9D%BF1114/%E9%95%BF%E6%B2%99%E5%B8%82%E9%85%92%E5%BA%97%E5%AD%A3%E5%BA%A6%E7%BB%8F%E8%90%A5%E5%88%86%E6%9E%90%E6%8A%A5%E5%91%8A.pdf",
    },
    {
        "1.报告名称":"岳阳市季度经营分析报告2024Q1",
        "2.生成日期":"2024-11-15",
        "3.报告分类":"季度经营分析报告",
        "4.数据范围":"岳阳市",
        "4.操作":"查看报告",
        "【操作】（新窗口）":"http://oss.datawang.com/examples/%E9%87%9C%E5%BA%95%E6%8A%BD%E8%96%AA%E6%8A%A5%E5%91%8A%E6%A8%A1%E6%9D%BF1114/%E9%95%BF%E6%B2%99%E5%B8%82%E9%85%92%E5%BA%97%E5%AD%A3%E5%BA%A6%E7%BB%8F%E8%90%A5%E5%88%86%E6%9E%90%E6%8A%A5%E5%91%8A.pdf",
    },
    {
        "1.报告名称":"常德市季度经营分析报告2024Q1",
        "2.生成日期":"2024-11-15",
        "3.报告分类":"季度经营分析报告",
        "4.数据范围":"常德市",
        "4.操作":"查看报告",
        "【操作】（新窗口）":"http://oss.datawang.com/examples/%E9%87%9C%E5%BA%95%E6%8A%BD%E8%96%AA%E6%8A%A5%E5%91%8A%E6%A8%A1%E6%9D%BF1114/%E9%95%BF%E6%B2%99%E5%B8%82%E9%85%92%E5%BA%97%E5%AD%A3%E5%BA%A6%E7%BB%8F%E8%90%A5%E5%88%86%E6%9E%90%E6%8A%A5%E5%91%8A.pdf",
    },
    {
        "1.报告名称":"张家界市季度经营分析报告2024Q1",
        "2.生成日期":"2024-11-15",
        "3.报告分类":"季度经营分析报告",
        "4.数据范围":"张家界市",
        "4.操作":"查看报告",
        "【操作】（新窗口）":"http://oss.datawang.com/examples/%E9%87%9C%E5%BA%95%E6%8A%BD%E8%96%AA%E6%8A%A5%E5%91%8A%E6%A8%A1%E6%9D%BF1114/%E9%95%BF%E6%B2%99%E5%B8%82%E9%85%92%E5%BA%97%E5%AD%A3%E5%BA%A6%E7%BB%8F%E8%90%A5%E5%88%86%E6%9E%90%E6%8A%A5%E5%91%8A.pdf",
    },
    {
        "1.报告名称":"益阳市季度经营分析报告2024Q1",
        "2.生成日期":"2024-11-15",
        "3.报告分类":"季度经营分析报告",
        "4.数据范围":"益阳市",
        "4.操作":"查看报告",
        "【操作】（新窗口）":"http://oss.datawang.com/examples/%E9%87%9C%E5%BA%95%E6%8A%BD%E8%96%AA%E6%8A%A5%E5%91%8A%E6%A8%A1%E6%9D%BF1114/%E9%95%BF%E6%B2%99%E5%B8%82%E9%85%92%E5%BA%97%E5%AD%A3%E5%BA%A6%E7%BB%8F%E8%90%A5%E5%88%86%E6%9E%90%E6%8A%A5%E5%91%8A.pdf",
    },
    {
        "1.报告名称":"郴州市季度经营分析报告2024Q1",
        "2.生成日期":"2024-11-15",
        "3.报告分类":"季度经营分析报告",
        "4.数据范围":"郴州市",
        "4.操作":"查看报告",
        "【操作】（新窗口）":"http://oss.datawang.com/examples/%E9%87%9C%E5%BA%95%E6%8A%BD%E8%96%AA%E6%8A%A5%E5%91%8A%E6%A8%A1%E6%9D%BF1114/%E9%95%BF%E6%B2%99%E5%B8%82%E9%85%92%E5%BA%97%E5%AD%A3%E5%BA%A6%E7%BB%8F%E8%90%A5%E5%88%86%E6%9E%90%E6%8A%A5%E5%91%8A.pdf",
    }
]