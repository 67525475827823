export default [
    {
        "1.任务名称":"报告生成-20241115-01",
        "2.生成日期":"2024-11-15", 
        "3.操作":"查看详情",
        "【操作】":"/xiehui/report-task?task_name=报告生成-20241115-01",
    },
    {
        "1.任务名称":"报告生成-20241114-01",
        "2.生成日期":"2024-11-14",
        "3.操作":"查看详情", 
        "【操作】":"/xiehui/report-task?task_name=报告生成-20241114-01",
    },
    {
        "1.任务名称":"报告生成-20241113-02",
        "2.生成日期":"2024-11-13",
        "3.操作":"查看详情",
        "【操作】":"/xiehui/report-task?task_name=报告生成-20241113-02",
    },
    {
        "1.任务名称":"报告生成-20241113-01", 
        "2.生成日期":"2024-11-13",
        "3.操作":"查看详情",
        "【操作】":"/xiehui/report-task?task_name=报告生成-20241113-01",
    },
    {
        "1.任务名称":"报告生成-20241112-01",
        "2.生成日期":"2024-11-12",
        "3.操作":"查看详情",
        "【操作】":"/xiehui/report-task?task_name=报告生成-20241112-01",
    },
]