export default {
    "GreenTree Hospitality Group": [
        {
            "name": "GHG 2023H1 ER 20230919 - final3 -clean",
            "display": "GHG 2023H1 ER 20230919 - final3 -clean",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/GreenTree Hospitality Group/GHG 2023H1 ER 20230919 - final3 -clean.pdf"
        },
        {
            "name": "2023  (20-F) 2024-04-30",
            "display": "2023  (20-F) 2024-04-30",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/GreenTree Hospitality Group/2023  (20-F) 2024-04-30.pdf_.pdf"
        }
    ],
    "Hotel Grand（大中酒店有限公司）": [
        {
            "name": "HGC Sustainability Report FY2023",
            "display": "HGC Sustainability Report FY2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/Hotel Grand（大中酒店有限公司）/HGC Sustainability Report FY2023.pdf"
        },
        {
            "name": "HGC Annual Report FY2023",
            "display": "HGC Annual Report FY2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/Hotel Grand（大中酒店有限公司）/HGC Annual Report FY2023.pdf"
        }
    ],
    "Hotel Royal（京华大酒店）": [
        {
            "name": "Hotel Royal AR2023",
            "display": "Hotel Royal AR2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/Hotel Royal（京华大酒店）/Hotel Royal AR2023.pdf"
        }
    ],
    "万科企业股份有限公司": [
        {
            "name": "万科A：2024年一季度报告",
            "display": "万科A：2024年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/万科企业股份有限公司/万科A：2024年一季度报告.pdf"
        },
        {
            "name": "万科A：2023年半年度报告",
            "display": "万科A：2023年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/万科企业股份有限公司/万科A：2023年半年度报告.pdf"
        },
        {
            "name": "万科A：2023年三季度报告",
            "display": "万科A：2023年三季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/万科企业股份有限公司/万科A：2023年三季度报告.pdf"
        },
        {
            "name": "万科A：2023年一季度报告",
            "display": "万科A：2023年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/万科企业股份有限公司/万科A：2023年一季度报告.pdf"
        },
        {
            "name": "万科-可持续发展报告2023",
            "display": "万科-可持续发展报告2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/万科企业股份有限公司/万科-可持续发展报告2023.pdf"
        },
        {
            "name": "万科-2024半年报",
            "display": "万科-2024半年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/万科企业股份有限公司/万科-2024半年报.pdf"
        },
        {
            "name": "万科-2023年报",
            "display": "万科-2023年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/万科企业股份有限公司/万科-2023年报.pdf"
        }
    ],
    "万豪国际酒店集团": [
        {
            "name": "Marriott-2023-Serve-360-ESG-Report-accessible",
            "display": "Marriott-2023-Serve-360-ESG-Report-accessible",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/万豪国际酒店集团/Marriott-2023-Serve-360-ESG-Report-accessible.pdf"
        },
        {
            "name": "MAR2024Q3",
            "display": "MAR2024Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/万豪国际酒店集团/MAR2024Q3.pdf"
        },
        {
            "name": "MAR2024Q2",
            "display": "MAR2024Q2",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/万豪国际酒店集团/MAR2024Q2.pdf"
        },
        {
            "name": "MAR2024Q1",
            "display": "MAR2024Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/万豪国际酒店集团/MAR2024Q1.pdf"
        },
        {
            "name": "MAR2023Q3",
            "display": "MAR2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/万豪国际酒店集团/MAR2023Q3.pdf"
        },
        {
            "name": "MAR2023Q2",
            "display": "MAR2023Q2",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/万豪国际酒店集团/MAR2023Q2.pdf"
        },
        {
            "name": "MAR2023Q1",
            "display": "MAR2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/万豪国际酒店集团/MAR2023Q1.pdf"
        },
        {
            "name": "MAR2023",
            "display": "MAR2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/万豪国际酒店集团/MAR2023.pdf"
        }
    ],
    "万达酒店发展有限公司": [
        {
            "name": "万达酒店-2024半年报",
            "display": "万达酒店-2024半年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/万达酒店发展有限公司/万达酒店-2024半年报.pdf"
        },
        {
            "name": "万达酒店-2023年报",
            "display": "万达酒店-2023年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/万达酒店发展有限公司/万达酒店-2023年报.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/万达酒店发展有限公司/2023H.pdf"
        }
    ],
    "上海城投控股股份有限公司": [
        {
            "name": "2024semi",
            "display": "2024semi",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/上海城投控股股份有限公司/2024semi.pdf"
        },
        {
            "name": "2024Q1",
            "display": "2024Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/上海城投控股股份有限公司/2024Q1.pdf"
        },
        {
            "name": "2023semi",
            "display": "2023semi",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/上海城投控股股份有限公司/2023semi.pdf"
        },
        {
            "name": "2023Q3",
            "display": "2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/上海城投控股股份有限公司/2023Q3.pdf"
        },
        {
            "name": "2023Q1",
            "display": "2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/上海城投控股股份有限公司/2023Q1.pdf"
        },
        {
            "name": "2023CSR",
            "display": "2023CSR",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/上海城投控股股份有限公司/2023CSR.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/上海城投控股股份有限公司/2023.pdf"
        }
    ],
    "上海豫园旅游商城（集团）股份有限公司": [
        {
            "name": "2024Q3",
            "display": "2024Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/上海豫园旅游商城（集团）股份有限公司/2024Q3.pdf"
        },
        {
            "name": "2024Q1",
            "display": "2024Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/上海豫园旅游商城（集团）股份有限公司/2024Q1.pdf"
        },
        {
            "name": "2024H1",
            "display": "2024H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/上海豫园旅游商城（集团）股份有限公司/2024H1.pdf"
        },
        {
            "name": "2023Q3",
            "display": "2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/上海豫园旅游商城（集团）股份有限公司/2023Q3.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/上海豫园旅游商城（集团）股份有限公司/2023.pdf"
        }
    ],
    "上海锦江国际酒店股份有限公司": [
        {
            "name": "锦江酒店2024Q3",
            "display": "锦江酒店2024Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/上海锦江国际酒店股份有限公司/锦江酒店2024Q3.pdf"
        },
        {
            "name": "锦江酒店2024Q1",
            "display": "锦江酒店2024Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/上海锦江国际酒店股份有限公司/锦江酒店2024Q1.PDF"
        },
        {
            "name": "锦江酒店2024H1",
            "display": "锦江酒店2024H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/上海锦江国际酒店股份有限公司/锦江酒店2024H1.PDF"
        },
        {
            "name": "锦江酒店2023Q3",
            "display": "锦江酒店2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/上海锦江国际酒店股份有限公司/锦江酒店2023Q3.pdf"
        },
        {
            "name": "锦江酒店2023Q1",
            "display": "锦江酒店2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/上海锦江国际酒店股份有限公司/锦江酒店2023Q1.pdf"
        },
        {
            "name": "锦江酒店2023H1",
            "display": "锦江酒店2023H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/上海锦江国际酒店股份有限公司/锦江酒店2023H1.pdf"
        },
        {
            "name": "锦江酒店2023",
            "display": "锦江酒店2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/上海锦江国际酒店股份有限公司/锦江酒店2023.PDF"
        },
        {
            "name": "____2023_____________ESG___",
            "display": "____2023_____________ESG___",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/上海锦江国际酒店股份有限公司/____2023_____________ESG___.pdf"
        }
    ],
    "世茂喜达酒店管理集团": [
        {
            "name": "世茂集团：2023年报",
            "display": "世茂集团：2023年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/世茂喜达酒店管理集团/世茂集团：2023年报.PDF"
        },
        {
            "name": "2024H",
            "display": "2024H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/世茂喜达酒店管理集团/2024H.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/世茂喜达酒店管理集团/2023H.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/世茂喜达酒店管理集团/2023ESG.pdf"
        }
    ],
    "中信股份": [
        {
            "name": "2024H",
            "display": "2024H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中信股份/2024H.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中信股份/2023H.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中信股份/2023ESG.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中信股份/2023.pdf"
        }
    ],
    "中国中铁股份有限公司": [
        {
            "name": "中国中铁2024年第一季度报告",
            "display": "中国中铁2024年第一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国中铁股份有限公司/中国中铁2024年第一季度报告.pdf"
        },
        {
            "name": "中国中铁2024年半年度报告（印刷版）",
            "display": "中国中铁2024年半年度报告（印刷版）",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国中铁股份有限公司/中国中铁2024年半年度报告（印刷版）.pdf"
        },
        {
            "name": "中国中铁2023年第三季度报告",
            "display": "中国中铁2023年第三季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国中铁股份有限公司/中国中铁2023年第三季度报告.pdf"
        },
        {
            "name": "中国中铁2023年年度报告（A股印刷版）",
            "display": "中国中铁2023年年度报告（A股印刷版）",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国中铁股份有限公司/中国中铁2023年年度报告（A股印刷版）.pdf"
        },
        {
            "name": "中国中铁2023年半年度报告",
            "display": "中国中铁2023年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国中铁股份有限公司/中国中铁2023年半年度报告.pdf"
        },
        {
            "name": "2023年第一季度报告",
            "display": "2023年第一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国中铁股份有限公司/2023年第一季度报告.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国中铁股份有限公司/2023ESG.pdf"
        }
    ],
    "中国光大控股": [
        {
            "name": "2024H1",
            "display": "2024H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国光大控股/2024H1.pdf"
        },
        {
            "name": "2023H1",
            "display": "2023H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国光大控股/2023H1.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国光大控股/2023ESG.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国光大控股/2023.pdf"
        }
    ],
    "中国恒大集团": [
        {
            "name": "2023H1",
            "display": "2023H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国恒大集团/2023H1.pdf"
        }
    ],
    "中国旅游集团中免股份有限公司": [
        {
            "name": "2024半年报",
            "display": "2024半年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国旅游集团中免股份有限公司/2024半年报.pdf"
        },
        {
            "name": "2024Q1",
            "display": "2024Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国旅游集团中免股份有限公司/2024Q1.pdf"
        },
        {
            "name": "2023年报",
            "display": "2023年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国旅游集团中免股份有限公司/2023年报.pdf"
        },
        {
            "name": "2023半年报",
            "display": "2023半年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国旅游集团中免股份有限公司/2023半年报.pdf"
        },
        {
            "name": "2023Q3",
            "display": "2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国旅游集团中免股份有限公司/2023Q3.pdf"
        },
        {
            "name": "2023Q1",
            "display": "2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国旅游集团中免股份有限公司/2023Q1.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国旅游集团中免股份有限公司/2023ESG.pdf"
        },
        {
            "name": "2023ESG-English",
            "display": "2023ESG-English",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国旅游集团中免股份有限公司/2023ESG-English.pdf"
        }
    ],
    "中国海外发展有限公司": [
        {
            "name": "2024H1",
            "display": "2024H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国海外发展有限公司/2024H1.pdf"
        },
        {
            "name": "2023_1",
            "display": "2023_1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国海外发展有限公司/2023_1.pdf"
        },
        {
            "name": "2023H1",
            "display": "2023H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国海外发展有限公司/2023H1.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国海外发展有限公司/2023.pdf"
        }
    ],
    "中国生态旅游集团有限公司": [
        {
            "name": "2024031900264_c",
            "display": "2024031900264_c",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国生态旅游集团有限公司/2024031900264_c.pdf"
        },
        {
            "name": "2023031500570_c",
            "display": "2023031500570_c",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国生态旅游集团有限公司/2023031500570_c.pdf"
        }
    ],
    "中国诚通发展集团有限公司": [
        {
            "name": "诚通-ESG2023",
            "display": "诚通-ESG2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国诚通发展集团有限公司/诚通-ESG2023.pdf"
        },
        {
            "name": "诚通-2023年报",
            "display": "诚通-2023年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国诚通发展集团有限公司/诚通-2023年报.pdf"
        },
        {
            "name": "2024H1",
            "display": "2024H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国诚通发展集团有限公司/2024H1.pdf"
        },
        {
            "name": "2023H1",
            "display": "2023H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国诚通发展集团有限公司/2023H1.pdf"
        }
    ],
    "中国金茂控股集团有限公司": [
        {
            "name": "2023中期报告",
            "display": "2023中期报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国金茂控股集团有限公司/2023中期报告.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国金茂控股集团有限公司/2023ESG.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中国金茂控股集团有限公司/2023.pdf"
        }
    ],
    "中青旅控股股份有限公司": [
        {
            "name": "2024Q3",
            "display": "2024Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中青旅控股股份有限公司/2024Q3.pdf"
        },
        {
            "name": "2024Q1",
            "display": "2024Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中青旅控股股份有限公司/2024Q1.pdf"
        },
        {
            "name": "2024H1",
            "display": "2024H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中青旅控股股份有限公司/2024H1.pdf"
        },
        {
            "name": "2023Q3",
            "display": "2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中青旅控股股份有限公司/2023Q3.pdf"
        },
        {
            "name": "2023Q1",
            "display": "2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中青旅控股股份有限公司/2023Q1.pdf"
        },
        {
            "name": "2023H1",
            "display": "2023H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中青旅控股股份有限公司/2023H1.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/中青旅控股股份有限公司/2023.pdf"
        }
    ],
    "丽呈酒店集团": [
        {
            "name": "攜程集團2023年環境、社會及管治報告",
            "display": "攜程集團2023年環境、社會及管治報告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/丽呈酒店集团/攜程集團2023年環境、社會及管治報告.pdf"
        },
        {
            "name": "1 2024 Q2 Earnings Press Release",
            "display": "1 2024 Q2 Earnings Press Release",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/丽呈酒店集团/1 2024 Q2 Earnings Press Release.pdf"
        },
        {
            "name": "1 2024 Q1 Earnings Press Release",
            "display": "1 2024 Q1 Earnings Press Release",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/丽呈酒店集团/1 2024 Q1 Earnings Press Release.pdf"
        },
        {
            "name": "1 2023 Q3 Earnings Press Release",
            "display": "1 2023 Q3 Earnings Press Release",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/丽呈酒店集团/1 2023 Q3 Earnings Press Release.pdf"
        },
        {
            "name": "1 2023 Q2 Earnings Press Release",
            "display": "1 2023 Q2 Earnings Press Release",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/丽呈酒店集团/1 2023 Q2 Earnings Press Release.pdf"
        },
        {
            "name": "1 2023 Q1 Earnings Press Release",
            "display": "1 2023 Q1 Earnings Press Release",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/丽呈酒店集团/1 2023 Q1 Earnings Press Release.pdf"
        }
    ],
    "丽江玉龙旅游股份有限公司": [
        {
            "name": "丽江股份：2024年半年度报告",
            "display": "丽江股份：2024年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/丽江玉龙旅游股份有限公司/丽江股份：2024年半年度报告.pdf"
        },
        {
            "name": "丽江股份：2024年一季度报告",
            "display": "丽江股份：2024年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/丽江玉龙旅游股份有限公司/丽江股份：2024年一季度报告.pdf"
        },
        {
            "name": "丽江股份：2023年年度报告",
            "display": "丽江股份：2023年年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/丽江玉龙旅游股份有限公司/丽江股份：2023年年度报告.pdf"
        },
        {
            "name": "丽江股份：2023年半年度报告",
            "display": "丽江股份：2023年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/丽江玉龙旅游股份有限公司/丽江股份：2023年半年度报告.pdf"
        },
        {
            "name": "丽江股份：2023年三季度报告",
            "display": "丽江股份：2023年三季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/丽江玉龙旅游股份有限公司/丽江股份：2023年三季度报告.pdf"
        },
        {
            "name": "丽江股份：2023年一季度报告",
            "display": "丽江股份：2023年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/丽江玉龙旅游股份有限公司/丽江股份：2023年一季度报告.pdf"
        }
    ],
    "九龙仓酒店管理有限公司": [
        {
            "name": "九龙仓-2023年报",
            "display": "九龙仓-2023年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/九龙仓酒店管理有限公司/九龙仓-2023年报.pdf"
        },
        {
            "name": "2024H",
            "display": "2024H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/九龙仓酒店管理有限公司/2024H.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/九龙仓酒店管理有限公司/2023H.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/九龙仓酒店管理有限公司/2023ESG.pdf"
        }
    ],
    "云南城投置业股份有限公司": [
        {
            "name": "2024SEMI",
            "display": "2024SEMI",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/云南城投置业股份有限公司/2024SEMI.pdf"
        },
        {
            "name": "2024Q1",
            "display": "2024Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/云南城投置业股份有限公司/2024Q1.pdf"
        },
        {
            "name": "2023SEMI",
            "display": "2023SEMI",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/云南城投置业股份有限公司/2023SEMI.pdf"
        },
        {
            "name": "2023Q3",
            "display": "2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/云南城投置业股份有限公司/2023Q3.pdf"
        },
        {
            "name": "2023Q1",
            "display": "2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/云南城投置业股份有限公司/2023Q1.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/云南城投置业股份有限公司/2023ESG.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/云南城投置业股份有限公司/2023.pdf"
        }
    ],
    "云南旅游股份有限公司": [
        {
            "name": "云南旅游：2024年半年度报告",
            "display": "云南旅游：2024年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/云南旅游股份有限公司/云南旅游：2024年半年度报告.pdf"
        },
        {
            "name": "云南旅游：2024年三季度报告",
            "display": "云南旅游：2024年三季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/云南旅游股份有限公司/云南旅游：2024年三季度报告.pdf"
        },
        {
            "name": "云南旅游：2024年一季度报告",
            "display": "云南旅游：2024年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/云南旅游股份有限公司/云南旅游：2024年一季度报告.pdf"
        },
        {
            "name": "云南旅游：2023年年度报告",
            "display": "云南旅游：2023年年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/云南旅游股份有限公司/云南旅游：2023年年度报告.pdf"
        },
        {
            "name": "云南旅游：2023年半年度报告",
            "display": "云南旅游：2023年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/云南旅游股份有限公司/云南旅游：2023年半年度报告.pdf"
        },
        {
            "name": "云南旅游：2023年三季度报告",
            "display": "云南旅游：2023年三季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/云南旅游股份有限公司/云南旅游：2023年三季度报告.pdf"
        },
        {
            "name": "云南旅游：2023年一季度报告",
            "display": "云南旅游：2023年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/云南旅游股份有限公司/云南旅游：2023年一季度报告.pdf"
        }
    ],
    "亚朵集团": [
        {
            "name": "亚朵集团2023年度环境、社会与管治报告(中文)",
            "display": "亚朵集团2023年度环境、社会与管治报告(中文)",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/亚朵集团/亚朵集团2023年度环境、社会与管治报告(中文).pdf"
        },
        {
            "name": "亚朵-2024Q2季度报",
            "display": "亚朵-2024Q2季度报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/亚朵集团/亚朵-2024Q2季度报.pdf"
        },
        {
            "name": "亚朵-2024Q1季度报",
            "display": "亚朵-2024Q1季度报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/亚朵集团/亚朵-2024Q1季度报.pdf"
        },
        {
            "name": "亚朵-2023年报",
            "display": "亚朵-2023年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/亚朵集团/亚朵-2023年报.pdf"
        },
        {
            "name": "2023Q4",
            "display": "2023Q4",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/亚朵集团/2023Q4.pdf"
        },
        {
            "name": "2023Q1",
            "display": "2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/亚朵集团/2023Q1.pdf"
        }
    ],
    "保利酒店集团": [
        {
            "name": "保利发展：保利发展控股集团股份有限公司2024年第一季度报告",
            "display": "保利发展：保利发展控股集团股份有限公司2024年第一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/保利酒店集团/保利发展：保利发展控股集团股份有限公司2024年第一季度报告.PDF"
        },
        {
            "name": "保利发展：保利发展控股集团股份有限公司2024年半年度报告",
            "display": "保利发展：保利发展控股集团股份有限公司2024年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/保利酒店集团/保利发展：保利发展控股集团股份有限公司2024年半年度报告.PDF"
        },
        {
            "name": "保利发展：保利发展控股集团股份有限公司2023年年度报告",
            "display": "保利发展：保利发展控股集团股份有限公司2023年年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/保利酒店集团/保利发展：保利发展控股集团股份有限公司2023年年度报告.PDF"
        },
        {
            "name": "2023中",
            "display": "2023中",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/保利酒店集团/2023中.pdf"
        },
        {
            "name": "2023Q3",
            "display": "2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/保利酒店集团/2023Q3.pdf"
        },
        {
            "name": "2023Q1",
            "display": "2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/保利酒店集团/2023Q1.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/保利酒店集团/2023H.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/保利酒店集团/2023.pdf"
        }
    ],
    "信和酒店（集团）有限公司": [
        {
            "name": "2024",
            "display": "2024",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/信和酒店（集团）有限公司/2024.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/信和酒店（集团）有限公司/2023.pdf"
        }
    ],
    "凤悦酒店管理（广东）有限公司": [
        {
            "name": "碧桂园-2023半年报",
            "display": "碧桂园-2023半年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/凤悦酒店管理（广东）有限公司/碧桂园-2023半年报.pdf"
        }
    ],
    "凯悦酒店集团": [
        {
            "name": "凯悦2024Q3",
            "display": "凯悦2024Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/凯悦酒店集团/凯悦2024Q3.pdf"
        },
        {
            "name": "凯悦2024Q2",
            "display": "凯悦2024Q2",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/凯悦酒店集团/凯悦2024Q2.pdf"
        },
        {
            "name": "凯悦2024Q1",
            "display": "凯悦2024Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/凯悦酒店集团/凯悦2024Q1.pdf"
        },
        {
            "name": "凯悦2023Q3",
            "display": "凯悦2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/凯悦酒店集团/凯悦2023Q3.pdf"
        },
        {
            "name": "凯悦2023Q2",
            "display": "凯悦2023Q2",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/凯悦酒店集团/凯悦2023Q2.pdf"
        },
        {
            "name": "凯悦2023Q1",
            "display": "凯悦2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/凯悦酒店集团/凯悦2023Q1.pdf"
        },
        {
            "name": "凯悦-2023财务年报",
            "display": "凯悦-2023财务年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/凯悦酒店集团/凯悦-2023财务年报.pdf"
        },
        {
            "name": "2023WOCHighlights",
            "display": "2023WOCHighlights",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/凯悦酒店集团/2023WOCHighlights.pdf"
        }
    ],
    "凯联国际酒店有限公司": [
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/凯联国际酒店有限公司/2023H.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/凯联国际酒店有限公司/2023.pdf"
        }
    ],
    "北京首旅酒店（集团）股份有限公司": [
        {
            "name": "首旅2024Q3",
            "display": "首旅2024Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/北京首旅酒店（集团）股份有限公司/首旅2024Q3.pdf"
        },
        {
            "name": "首旅2024Q1",
            "display": "首旅2024Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/北京首旅酒店（集团）股份有限公司/首旅2024Q1.PDF"
        },
        {
            "name": "首旅2024H1",
            "display": "首旅2024H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/北京首旅酒店（集团）股份有限公司/首旅2024H1.PDF"
        },
        {
            "name": "首旅2023Q3",
            "display": "首旅2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/北京首旅酒店（集团）股份有限公司/首旅2023Q3.pdf"
        },
        {
            "name": "首旅2023Q1",
            "display": "首旅2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/北京首旅酒店（集团）股份有限公司/首旅2023Q1.pdf"
        },
        {
            "name": "首旅2023H1",
            "display": "首旅2023H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/北京首旅酒店（集团）股份有限公司/首旅2023H1.pdf"
        },
        {
            "name": "首旅2023",
            "display": "首旅2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/北京首旅酒店（集团）股份有限公司/首旅2023.PDF"
        },
        {
            "name": "北京首旅酒店（集团）股份有限公司2023年度ESG报告",
            "display": "北京首旅酒店（集团）股份有限公司2023年度ESG报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/北京首旅酒店（集团）股份有限公司/北京首旅酒店（集团）股份有限公司2023年度ESG报告.pdf"
        }
    ],
    "华住集团有限公司": [
        {
            "name": "HTHT2024Q2",
            "display": "HTHT2024Q2",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华住集团有限公司/HTHT2024Q2.pdf"
        },
        {
            "name": "HTHT2024Q1",
            "display": "HTHT2024Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华住集团有限公司/HTHT2024Q1.pdf"
        },
        {
            "name": "HTHT2023Q4",
            "display": "HTHT2023Q4",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华住集团有限公司/HTHT2023Q4.pdf"
        },
        {
            "name": "HTHT2023Q3",
            "display": "HTHT2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华住集团有限公司/HTHT2023Q3.pdf"
        },
        {
            "name": "HTHT2023Q2",
            "display": "HTHT2023Q2",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华住集团有限公司/HTHT2023Q2.pdf"
        },
        {
            "name": "HTHT2023Q1",
            "display": "HTHT2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华住集团有限公司/HTHT2023Q1.pdf"
        },
        {
            "name": "HTHT2023",
            "display": "HTHT2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华住集团有限公司/HTHT2023.pdf"
        },
        {
            "name": "H World Sustainability Report 2023",
            "display": "H World Sustainability Report 2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华住集团有限公司/H World Sustainability Report 2023.pdf"
        }
    ],
    "华夏幸福基业股份有限公司": [
        {
            "name": "2024SEMI",
            "display": "2024SEMI",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华夏幸福基业股份有限公司/2024SEMI.pdf"
        },
        {
            "name": "2024Q1",
            "display": "2024Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华夏幸福基业股份有限公司/2024Q1.pdf"
        },
        {
            "name": "2023SEMI",
            "display": "2023SEMI",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华夏幸福基业股份有限公司/2023SEMI.pdf"
        },
        {
            "name": "2023Q3",
            "display": "2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华夏幸福基业股份有限公司/2023Q3.pdf"
        },
        {
            "name": "2023Q1",
            "display": "2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华夏幸福基业股份有限公司/2023Q1.pdf"
        },
        {
            "name": "2023CSR",
            "display": "2023CSR",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华夏幸福基业股份有限公司/2023CSR.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华夏幸福基业股份有限公司/2023.pdf"
        }
    ],
    "华大酒店投资有限公司": [
        {
            "name": "2024H",
            "display": "2024H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华大酒店投资有限公司/2024H.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华大酒店投资有限公司/2023H.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华大酒店投资有限公司/2023ESG.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华大酒店投资有限公司/2023.pdf"
        }
    ],
    "华天酒店集团股份有限公司": [
        {
            "name": "华天酒店：2024年半年度报告",
            "display": "华天酒店：2024年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华天酒店集团股份有限公司/华天酒店：2024年半年度报告.pdf"
        },
        {
            "name": "华天酒店：2024年一季度报告",
            "display": "华天酒店：2024年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华天酒店集团股份有限公司/华天酒店：2024年一季度报告.pdf"
        },
        {
            "name": "华天酒店：2023年年度报告",
            "display": "华天酒店：2023年年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华天酒店集团股份有限公司/华天酒店：2023年年度报告.pdf"
        },
        {
            "name": "华天酒店：2023年半年度报告",
            "display": "华天酒店：2023年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华天酒店集团股份有限公司/华天酒店：2023年半年度报告.pdf"
        },
        {
            "name": "华天酒店：2023年三季度报告",
            "display": "华天酒店：2023年三季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华天酒店集团股份有限公司/华天酒店：2023年三季度报告.pdf"
        },
        {
            "name": "华天酒店：2023年一季度报告",
            "display": "华天酒店：2023年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/华天酒店集团股份有限公司/华天酒店：2023年一季度报告.pdf"
        }
    ],
    "厦门建发股份有限公司": [
        {
            "name": "2024半年报",
            "display": "2024半年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/厦门建发股份有限公司/2024半年报.pdf"
        },
        {
            "name": "2024Q1",
            "display": "2024Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/厦门建发股份有限公司/2024Q1.pdf"
        },
        {
            "name": "2023可持续发展报告",
            "display": "2023可持续发展报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/厦门建发股份有限公司/2023可持续发展报告.pdf"
        },
        {
            "name": "2023半年报",
            "display": "2023半年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/厦门建发股份有限公司/2023半年报.pdf"
        },
        {
            "name": "2023Q3",
            "display": "2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/厦门建发股份有限公司/2023Q3.pdf"
        },
        {
            "name": "2023Q1",
            "display": "2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/厦门建发股份有限公司/2023Q1.pdf"
        },
        {
            "name": "2023ESG-English",
            "display": "2023ESG-English",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/厦门建发股份有限公司/2023ESG-English.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/厦门建发股份有限公司/2023.pdf"
        }
    ],
    "君亭酒店集团股份有限公司": [
        {
            "name": "君亭酒店：2023年半年度报告",
            "display": "君亭酒店：2023年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/君亭酒店集团股份有限公司/君亭酒店：2023年半年度报告.pdf"
        },
        {
            "name": "君亭酒店：2023年三季度报告",
            "display": "君亭酒店：2023年三季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/君亭酒店集团股份有限公司/君亭酒店：2023年三季度报告.pdf"
        },
        {
            "name": "君亭酒店：2023年一季度报告",
            "display": "君亭酒店：2023年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/君亭酒店集团股份有限公司/君亭酒店：2023年一季度报告.pdf"
        },
        {
            "name": "君亭-2024财务半年度报告",
            "display": "君亭-2024财务半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/君亭酒店集团股份有限公司/君亭-2024财务半年度报告.pdf"
        },
        {
            "name": "君亭-2024Q1季度报告",
            "display": "君亭-2024Q1季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/君亭酒店集团股份有限公司/君亭-2024Q1季度报告.pdf"
        },
        {
            "name": "君亭-2023年度报告",
            "display": "君亭-2023年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/君亭酒店集团股份有限公司/君亭-2023年度报告.pdf"
        }
    ],
    "嘉华国际集团有限公司": [
        {
            "name": "嘉华国际-2024半年报",
            "display": "嘉华国际-2024半年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/嘉华国际集团有限公司/嘉华国际-2024半年报.pdf"
        },
        {
            "name": "嘉华国际-2023年报",
            "display": "嘉华国际-2023年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/嘉华国际集团有限公司/嘉华国际-2023年报.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/嘉华国际集团有限公司/2023H.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/嘉华国际集团有限公司/2023ESG.pdf"
        }
    ],
    "嘉里建设有限公司": [
        {
            "name": "嘉里建设2024H1",
            "display": "嘉里建设2024H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/嘉里建设有限公司/嘉里建设2024H1.pdf"
        },
        {
            "name": "嘉里建设2023H1",
            "display": "嘉里建设2023H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/嘉里建设有限公司/嘉里建设2023H1.pdf"
        },
        {
            "name": "KPL_SR2023_HK",
            "display": "KPL_SR2023_HK",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/嘉里建设有限公司/KPL_SR2023_HK.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/嘉里建设有限公司/2023.pdf"
        }
    ],
    "大中华酒店（香港）有限公司": [
        {
            "name": "大中华-2023年报",
            "display": "大中华-2023年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/大中华酒店（香港）有限公司/大中华-2023年报.PDF"
        },
        {
            "name": "2024H",
            "display": "2024H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/大中华酒店（香港）有限公司/2024H.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/大中华酒店（香港）有限公司/2023H.pdf"
        }
    ],
    "大悦城控股集团股份有限公司": [
        {
            "name": "大悦城：2024年半年度报告",
            "display": "大悦城：2024年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/大悦城控股集团股份有限公司/大悦城：2024年半年度报告.pdf"
        },
        {
            "name": "大悦城：2024年一季度报告",
            "display": "大悦城：2024年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/大悦城控股集团股份有限公司/大悦城：2024年一季度报告.pdf"
        },
        {
            "name": "大悦城：2023年年度报告",
            "display": "大悦城：2023年年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/大悦城控股集团股份有限公司/大悦城：2023年年度报告.pdf"
        },
        {
            "name": "大悦城：2023年半年度报告",
            "display": "大悦城：2023年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/大悦城控股集团股份有限公司/大悦城：2023年半年度报告.pdf"
        },
        {
            "name": "大悦城：2023年三季度报告",
            "display": "大悦城：2023年三季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/大悦城控股集团股份有限公司/大悦城：2023年三季度报告.pdf"
        },
        {
            "name": "大悦城：2023年一季度报告",
            "display": "大悦城：2023年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/大悦城控股集团股份有限公司/大悦城：2023年一季度报告.pdf"
        },
        {
            "name": "2023CSR",
            "display": "2023CSR",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/大悦城控股集团股份有限公司/2023CSR.pdf"
        }
    ],
    "太古酒店集团": [
        {
            "name": "2024H",
            "display": "2024H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/太古酒店集团/2024H.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/太古酒店集团/2023H.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/太古酒店集团/2023ESG.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/太古酒店集团/2023.pdf"
        }
    ],
    "安徽九华山旅游发展股份有限公司": [
        {
            "name": "24Q3",
            "display": "24Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/安徽九华山旅游发展股份有限公司/24Q3.pdf"
        },
        {
            "name": "24Q1",
            "display": "24Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/安徽九华山旅游发展股份有限公司/24Q1.pdf"
        },
        {
            "name": "24H1",
            "display": "24H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/安徽九华山旅游发展股份有限公司/24H1.pdf"
        },
        {
            "name": "23Q3",
            "display": "23Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/安徽九华山旅游发展股份有限公司/23Q3.pdf"
        },
        {
            "name": "23Q1",
            "display": "23Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/安徽九华山旅游发展股份有限公司/23Q1.pdf"
        },
        {
            "name": "23H1",
            "display": "23H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/安徽九华山旅游发展股份有限公司/23H1.pdf"
        },
        {
            "name": "23",
            "display": "23",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/安徽九华山旅游发展股份有限公司/23.pdf"
        }
    ],
    "宝龙酒店集团": [
        {
            "name": "2024H",
            "display": "2024H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/宝龙酒店集团/2024H.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/宝龙酒店集团/2023H.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/宝龙酒店集团/2023ESG.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/宝龙酒店集团/2023.pdf"
        }
    ],
    "富豪酒店国际控股有限公司": [
        {
            "name": "2024H",
            "display": "2024H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/富豪酒店国际控股有限公司/2024H.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/富豪酒店国际控股有限公司/2023H.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/富豪酒店国际控股有限公司/2023ESG.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/富豪酒店国际控股有限公司/2023.pdf"
        }
    ],
    "尊蓝酒店管理有限公司": [
        {
            "name": "绿城-2023年报",
            "display": "绿城-2023年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/尊蓝酒店管理有限公司/绿城-2023年报.pdf"
        },
        {
            "name": "2024H",
            "display": "2024H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/尊蓝酒店管理有限公司/2024H.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/尊蓝酒店管理有限公司/2023H.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/尊蓝酒店管理有限公司/2023ESG.pdf"
        }
    ],
    "希尔顿酒店集团": [
        {
            "name": "2024Q3",
            "display": "2024Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/希尔顿酒店集团/2024Q3.pdf"
        },
        {
            "name": "2024Q2",
            "display": "2024Q2",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/希尔顿酒店集团/2024Q2.pdf"
        },
        {
            "name": "2024Q1",
            "display": "2024Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/希尔顿酒店集团/2024Q1.pdf"
        },
        {
            "name": "2023_1",
            "display": "2023_1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/希尔顿酒店集团/2023_1.pdf"
        },
        {
            "name": "2023Q3",
            "display": "2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/希尔顿酒店集团/2023Q3.pdf"
        },
        {
            "name": "2023Q2",
            "display": "2023Q2",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/希尔顿酒店集团/2023Q2.pdf"
        },
        {
            "name": "2023Q1",
            "display": "2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/希尔顿酒店集团/2023Q1.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/希尔顿酒店集团/2023.pdf"
        }
    ],
    "广州富力地产股份有限公司": [
        {
            "name": "富力-ESG报告2023",
            "display": "富力-ESG报告2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/广州富力地产股份有限公司/富力-ESG报告2023.pdf"
        },
        {
            "name": "富力-2024半年报",
            "display": "富力-2024半年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/广州富力地产股份有限公司/富力-2024半年报.pdf"
        },
        {
            "name": "富力-2023年报",
            "display": "富力-2023年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/广州富力地产股份有限公司/富力-2023年报.pdf"
        },
        {
            "name": "2023H1",
            "display": "2023H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/广州富力地产股份有限公司/2023H1.pdf"
        }
    ],
    "广州岭南集团控股股份有限公司": [
        {
            "name": "岭南控股：2023年年度报告",
            "display": "岭南控股：2023年年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/广州岭南集团控股股份有限公司/岭南控股：2023年年度报告.pdf"
        },
        {
            "name": "岭南控股：2023年半年度报告",
            "display": "岭南控股：2023年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/广州岭南集团控股股份有限公司/岭南控股：2023年半年度报告.pdf"
        },
        {
            "name": "岭南控股：2023年三季度报告",
            "display": "岭南控股：2023年三季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/广州岭南集团控股股份有限公司/岭南控股：2023年三季度报告.pdf"
        },
        {
            "name": "岭南控股：2023年一季度报告",
            "display": "岭南控股：2023年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/广州岭南集团控股股份有限公司/岭南控股：2023年一季度报告.pdf"
        },
        {
            "name": "岭南-2024年半年度报告",
            "display": "岭南-2024年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/广州岭南集团控股股份有限公司/岭南-2024年半年度报告.pdf"
        },
        {
            "name": "岭南-2024年一季度报告",
            "display": "岭南-2024年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/广州岭南集团控股股份有限公司/岭南-2024年一季度报告.pdf"
        },
        {
            "name": "2023社会价值报告",
            "display": "2023社会价值报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/广州岭南集团控股股份有限公司/2023社会价值报告.pdf"
        }
    ],
    "广州珠江发展集团股份有限公司": [
        {
            "name": "2024SEMI",
            "display": "2024SEMI",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/广州珠江发展集团股份有限公司/2024SEMI.pdf"
        },
        {
            "name": "2024Q1",
            "display": "2024Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/广州珠江发展集团股份有限公司/2024Q1.pdf"
        },
        {
            "name": "2023SEMI",
            "display": "2023SEMI",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/广州珠江发展集团股份有限公司/2023SEMI.pdf"
        },
        {
            "name": "2023Q3",
            "display": "2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/广州珠江发展集团股份有限公司/2023Q3.pdf"
        },
        {
            "name": "2023Q1",
            "display": "2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/广州珠江发展集团股份有限公司/2023Q1.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/广州珠江发展集团股份有限公司/2023ESG.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/广州珠江发展集团股份有限公司/2023.pdf"
        }
    ],
    "开源控股有限公司": [
        {
            "name": "2024H",
            "display": "2024H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/开源控股有限公司/2024H.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/开源控股有限公司/2023H.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/开源控股有限公司/2023.pdf"
        }
    ],
    "招商局蛇口工业区控股股份有限公司": [
        {
            "name": "招商蛇口：2024年半年度报告",
            "display": "招商蛇口：2024年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/招商局蛇口工业区控股股份有限公司/招商蛇口：2024年半年度报告.pdf"
        },
        {
            "name": "招商蛇口：2024年一季度报告",
            "display": "招商蛇口：2024年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/招商局蛇口工业区控股股份有限公司/招商蛇口：2024年一季度报告.pdf"
        },
        {
            "name": "招商蛇口：2023年年度报告",
            "display": "招商蛇口：2023年年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/招商局蛇口工业区控股股份有限公司/招商蛇口：2023年年度报告.pdf"
        },
        {
            "name": "招商蛇口：2023年半年度报告",
            "display": "招商蛇口：2023年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/招商局蛇口工业区控股股份有限公司/招商蛇口：2023年半年度报告.pdf"
        },
        {
            "name": "招商蛇口：2023年三季度报告",
            "display": "招商蛇口：2023年三季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/招商局蛇口工业区控股股份有限公司/招商蛇口：2023年三季度报告.pdf"
        },
        {
            "name": "招商蛇口：2023年一季度报告",
            "display": "招商蛇口：2023年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/招商局蛇口工业区控股股份有限公司/招商蛇口：2023年一季度报告.pdf"
        },
        {
            "name": "2023可持续发展报告",
            "display": "2023可持续发展报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/招商局蛇口工业区控股股份有限公司/2023可持续发展报告.pdf"
        }
    ],
    "新华联文化旅游发展股份有限公司": [
        {
            "name": "新华联：2024年半年度报告",
            "display": "新华联：2024年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/新华联文化旅游发展股份有限公司/新华联：2024年半年度报告.pdf"
        },
        {
            "name": "新华联：2023年一季度报告",
            "display": "新华联：2023年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/新华联文化旅游发展股份有限公司/新华联：2023年一季度报告.pdf"
        },
        {
            "name": "_ST新联：2024年一季度报告",
            "display": "_ST新联：2024年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/新华联文化旅游发展股份有限公司/_ST新联：2024年一季度报告.pdf"
        },
        {
            "name": "_ST新联：2023年年度报告",
            "display": "_ST新联：2023年年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/新华联文化旅游发展股份有限公司/_ST新联：2023年年度报告.pdf"
        },
        {
            "name": "_ST新联：2023年半年度报告",
            "display": "_ST新联：2023年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/新华联文化旅游发展股份有限公司/_ST新联：2023年半年度报告.pdf"
        },
        {
            "name": "_ST新联：2023年三季度报告",
            "display": "_ST新联：2023年三季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/新华联文化旅游发展股份有限公司/_ST新联：2023年三季度报告.pdf"
        }
    ],
    "新城控股集团股份有限公司": [
        {
            "name": "2024半年报",
            "display": "2024半年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/新城控股集团股份有限公司/2024半年报.pdf"
        },
        {
            "name": "2024Q1",
            "display": "2024Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/新城控股集团股份有限公司/2024Q1.pdf"
        },
        {
            "name": "2023半年报",
            "display": "2023半年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/新城控股集团股份有限公司/2023半年报.pdf"
        },
        {
            "name": "2023development",
            "display": "2023development",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/新城控股集团股份有限公司/2023development.pdf"
        },
        {
            "name": "2023Q3",
            "display": "2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/新城控股集团股份有限公司/2023Q3.pdf"
        },
        {
            "name": "2023Q1",
            "display": "2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/新城控股集团股份有限公司/2023Q1.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/新城控股集团股份有限公司/2023.pdf"
        }
    ],
    "新都酒店集团": [
        {
            "name": "2023Q1",
            "display": "2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/新都酒店集团/2023Q1.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/新都酒店集团/2023H.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/新都酒店集团/2023.pdf"
        }
    ],
    "新鸿基地产发展有限公司": [
        {
            "name": "SHKP_SR_2024_CHI",
            "display": "SHKP_SR_2024_CHI",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/新鸿基地产发展有限公司/SHKP_SR_2024_CHI.pdf"
        },
        {
            "name": "2023_1",
            "display": "2023_1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/新鸿基地产发展有限公司/2023_1.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/新鸿基地产发展有限公司/2023.pdf"
        },
        {
            "name": "2023-24",
            "display": "2023-24",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/新鸿基地产发展有限公司/2023-24.pdf"
        }
    ],
    "朗廷酒店投资有限公司": [
        {
            "name": "朗廷2024H1",
            "display": "朗廷2024H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/朗廷酒店投资有限公司/朗廷2024H1.pdf"
        },
        {
            "name": "朗廷2023H1",
            "display": "朗廷2023H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/朗廷酒店投资有限公司/朗廷2023H1.pdf"
        },
        {
            "name": "朗廷-2023年年報",
            "display": "朗廷-2023年年報",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/朗廷酒店投资有限公司/朗廷-2023年年報.pdf"
        }
    ],
    "木棉花酒店管理集团": [
        {
            "name": "华润置地-可持续发展报告2023",
            "display": "华润置地-可持续发展报告2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/木棉花酒店管理集团/华润置地-可持续发展报告2023.pdf"
        },
        {
            "name": "华润置地-2023年报",
            "display": "华润置地-2023年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/木棉花酒店管理集团/华润置地-2023年报.pdf"
        },
        {
            "name": "2024H",
            "display": "2024H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/木棉花酒店管理集团/2024H.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/木棉花酒店管理集团/2023H.pdf"
        }
    ],
    "永乐华住酒店管理有限公司": [
        {
            "name": "融创-2023年报",
            "display": "融创-2023年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/永乐华住酒店管理有限公司/融创-2023年报.pdf"
        },
        {
            "name": "desktop",
            "display": "desktop",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/永乐华住酒店管理有限公司/desktop.ini"
        },
        {
            "name": "2024H",
            "display": "2024H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/永乐华住酒店管理有限公司/2024H.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/永乐华住酒店管理有限公司/2023H.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/永乐华住酒店管理有限公司/2023ESG.pdf"
        }
    ],
    "泛海酒店集团有限公司": [
        {
            "name": "2024",
            "display": "2024",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/泛海酒店集团有限公司/2024.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/泛海酒店集团有限公司/2023H.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/泛海酒店集团有限公司/2023.pdf"
        }
    ],
    "洲际酒店集团": [
        {
            "name": "洲际-企业社会责任和ESG报告2023",
            "display": "洲际-企业社会责任和ESG报告2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/洲际酒店集团/洲际-企业社会责任和ESG报告2023.pdf"
        },
        {
            "name": "2024Q3",
            "display": "2024Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/洲际酒店集团/2024Q3.pdf"
        },
        {
            "name": "2024Q1",
            "display": "2024Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/洲际酒店集团/2024Q1.pdf"
        },
        {
            "name": "2024H1",
            "display": "2024H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/洲际酒店集团/2024H1.pdf"
        },
        {
            "name": "2023H1",
            "display": "2023H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/洲际酒店集团/2023H1.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/洲际酒店集团/2023.pdf"
        }
    ],
    "浙江祥源文旅股份有限公司": [],
    "海天地悦旅集团有限公司": [
        {
            "name": "2024H",
            "display": "2024H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/海天地悦旅集团有限公司/2024H.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/海天地悦旅集团有限公司/2023H.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/海天地悦旅集团有限公司/2023.pdf"
        }
    ],
    "深圳华侨城股份有限公司": [
        {
            "name": "华侨城Ａ：2024年半年度报告",
            "display": "华侨城Ａ：2024年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/深圳华侨城股份有限公司/华侨城Ａ：2024年半年度报告.pdf"
        },
        {
            "name": "华侨城Ａ：2024年一季度报告",
            "display": "华侨城Ａ：2024年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/深圳华侨城股份有限公司/华侨城Ａ：2024年一季度报告.pdf"
        },
        {
            "name": "华侨城Ａ：2023年年度报告",
            "display": "华侨城Ａ：2023年年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/深圳华侨城股份有限公司/华侨城Ａ：2023年年度报告.pdf"
        },
        {
            "name": "华侨城Ａ：2023年半年度报告",
            "display": "华侨城Ａ：2023年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/深圳华侨城股份有限公司/华侨城Ａ：2023年半年度报告.pdf"
        },
        {
            "name": "华侨城Ａ：2023年三季度报告",
            "display": "华侨城Ａ：2023年三季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/深圳华侨城股份有限公司/华侨城Ａ：2023年三季度报告.pdf"
        },
        {
            "name": "华侨城Ａ：2023年一季度报告",
            "display": "华侨城Ａ：2023年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/深圳华侨城股份有限公司/华侨城Ａ：2023年一季度报告.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/深圳华侨城股份有限公司/2023ESG.pdf"
        }
    ],
    "温德姆酒店集团": [
        {
            "name": "温德姆-2024Q2财务季度报",
            "display": "温德姆-2024Q2财务季度报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/温德姆酒店集团/温德姆-2024Q2财务季度报.pdf"
        },
        {
            "name": "温德姆-2024Q1财务季度报",
            "display": "温德姆-2024Q1财务季度报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/温德姆酒店集团/温德姆-2024Q1财务季度报.pdf"
        },
        {
            "name": "温德姆-2023财务年报",
            "display": "温德姆-2023财务年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/温德姆酒店集团/温德姆-2023财务年报.pdf"
        },
        {
            "name": "WYNDHAM 2023Q3",
            "display": "WYNDHAM 2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/温德姆酒店集团/WYNDHAM 2023Q3.pdf"
        },
        {
            "name": "2024+ESG+Report+06042024+FINAL",
            "display": "2024+ESG+Report+06042024+FINAL",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/温德姆酒店集团/2024+ESG+Report+06042024+FINAL.pdf"
        },
        {
            "name": "2023Q3",
            "display": "2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/温德姆酒店集团/2023Q3.pdf"
        },
        {
            "name": "2023Q2",
            "display": "2023Q2",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/温德姆酒店集团/2023Q2.pdf"
        },
        {
            "name": "2023Q1",
            "display": "2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/温德姆酒店集团/2023Q1.pdf"
        },
        {
            "name": "2023 WH ESG Report - FINAL",
            "display": "2023 WH ESG Report - FINAL",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/温德姆酒店集团/2023 WH ESG Report - FINAL.pdf"
        }
    ],
    "珠海华发实业股份有限公司": [
        {
            "name": "2024半年报",
            "display": "2024半年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/珠海华发实业股份有限公司/2024半年报.pdf"
        },
        {
            "name": "2024Q1",
            "display": "2024Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/珠海华发实业股份有限公司/2024Q1.pdf"
        },
        {
            "name": "2023半年报",
            "display": "2023半年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/珠海华发实业股份有限公司/2023半年报.pdf"
        },
        {
            "name": "2023Q3",
            "display": "2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/珠海华发实业股份有限公司/2023Q3.pdf"
        },
        {
            "name": "2023Q1",
            "display": "2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/珠海华发实业股份有限公司/2023Q1.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/珠海华发实业股份有限公司/2023ESG.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/珠海华发实业股份有限公司/2023.pdf"
        }
    ],
    "瑞安房地产有限公司": [
        {
            "name": "2024H",
            "display": "2024H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/瑞安房地产有限公司/2024H.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/瑞安房地产有限公司/2023H.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/瑞安房地产有限公司/2023ESG.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/瑞安房地产有限公司/2023.pdf"
        }
    ],
    "百利保控股有限公司": [
        {
            "name": "2024H1",
            "display": "2024H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/百利保控股有限公司/2024H1.pdf"
        },
        {
            "name": "2023H1",
            "display": "2023H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/百利保控股有限公司/2023H1.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/百利保控股有限公司/2023ESG.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/百利保控股有限公司/2023.pdf"
        }
    ],
    "绿地酒店旅游集团": [
        {
            "name": "2024Q1",
            "display": "2024Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/绿地酒店旅游集团/2024Q1.pdf"
        },
        {
            "name": "2024H",
            "display": "2024H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/绿地酒店旅游集团/2024H.pdf"
        },
        {
            "name": "2023Q3",
            "display": "2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/绿地酒店旅游集团/2023Q3.pdf"
        },
        {
            "name": "2023Q1",
            "display": "2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/绿地酒店旅游集团/2023Q1.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/绿地酒店旅游集团/2023H.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/绿地酒店旅游集团/2023ESG.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/绿地酒店旅游集团/2023.pdf"
        }
    ],
    "苏宁环球股份有限公司": [
        {
            "name": "苏宁环球：2024年半年度报告",
            "display": "苏宁环球：2024年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/苏宁环球股份有限公司/苏宁环球：2024年半年度报告.pdf"
        },
        {
            "name": "苏宁环球：2024年一季度报告",
            "display": "苏宁环球：2024年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/苏宁环球股份有限公司/苏宁环球：2024年一季度报告.pdf"
        },
        {
            "name": "苏宁环球：2023年年度报告",
            "display": "苏宁环球：2023年年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/苏宁环球股份有限公司/苏宁环球：2023年年度报告.pdf"
        },
        {
            "name": "苏宁环球：2023年半年度报告",
            "display": "苏宁环球：2023年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/苏宁环球股份有限公司/苏宁环球：2023年半年度报告.pdf"
        },
        {
            "name": "苏宁环球：2023年三季度报告",
            "display": "苏宁环球：2023年三季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/苏宁环球股份有限公司/苏宁环球：2023年三季度报告.pdf"
        },
        {
            "name": "苏宁环球：2023年一季度报告",
            "display": "苏宁环球：2023年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/苏宁环球股份有限公司/苏宁环球：2023年一季度报告.pdf"
        }
    ],
    "英皇娱乐酒店有限公司": [],
    "荣盛房地产发展股份有限公司": [
        {
            "name": "荣盛发展：2024年半年度报告",
            "display": "荣盛发展：2024年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/荣盛房地产发展股份有限公司/荣盛发展：2024年半年度报告.pdf"
        },
        {
            "name": "荣盛发展：2024年三季度报告",
            "display": "荣盛发展：2024年三季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/荣盛房地产发展股份有限公司/荣盛发展：2024年三季度报告.pdf"
        },
        {
            "name": "荣盛发展：2024年一季度报告",
            "display": "荣盛发展：2024年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/荣盛房地产发展股份有限公司/荣盛发展：2024年一季度报告.pdf"
        },
        {
            "name": "荣盛发展：2023年年度报告",
            "display": "荣盛发展：2023年年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/荣盛房地产发展股份有限公司/荣盛发展：2023年年度报告.pdf"
        },
        {
            "name": "荣盛发展：2023年半年度报告",
            "display": "荣盛发展：2023年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/荣盛房地产发展股份有限公司/荣盛发展：2023年半年度报告.pdf"
        },
        {
            "name": "荣盛发展：2023年三季度报告",
            "display": "荣盛发展：2023年三季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/荣盛房地产发展股份有限公司/荣盛发展：2023年三季度报告.pdf"
        },
        {
            "name": "荣盛发展：2023年一季度报告",
            "display": "荣盛发展：2023年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/荣盛房地产发展股份有限公司/荣盛发展：2023年一季度报告.pdf"
        },
        {
            "name": "23ESG",
            "display": "23ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/荣盛房地产发展股份有限公司/23ESG.pdf"
        }
    ],
    "西域旅游开发股份有限公司": [
        {
            "name": "西域旅游：2024年半年度报告",
            "display": "西域旅游：2024年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/西域旅游开发股份有限公司/西域旅游：2024年半年度报告.pdf"
        },
        {
            "name": "西域旅游：2024年三季度报告",
            "display": "西域旅游：2024年三季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/西域旅游开发股份有限公司/西域旅游：2024年三季度报告.pdf"
        },
        {
            "name": "西域旅游：2023年一季度报告",
            "display": "西域旅游：2023年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/西域旅游开发股份有限公司/西域旅游：2023年一季度报告.pdf"
        },
        {
            "name": "_ST西域：2024年一季度报告",
            "display": "_ST西域：2024年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/西域旅游开发股份有限公司/_ST西域：2024年一季度报告.pdf"
        },
        {
            "name": "_ST西域：2023年年度报告",
            "display": "_ST西域：2023年年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/西域旅游开发股份有限公司/_ST西域：2023年年度报告.pdf"
        },
        {
            "name": "_ST西域：2023年半年度报告",
            "display": "_ST西域：2023年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/西域旅游开发股份有限公司/_ST西域：2023年半年度报告.pdf"
        },
        {
            "name": "_ST西域：2023年三季度报告",
            "display": "_ST西域：2023年三季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/西域旅游开发股份有限公司/_ST西域：2023年三季度报告.pdf"
        }
    ],
    "西安曲江文化旅游股份有限公司": [],
    "金地(集团)股份有限公司": [
        {
            "name": "2024半年报",
            "display": "2024半年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金地(集团)股份有限公司/2024半年报.pdf"
        },
        {
            "name": "2024Q1",
            "display": "2024Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金地(集团)股份有限公司/2024Q1.pdf"
        },
        {
            "name": "2023可持续发展报告",
            "display": "2023可持续发展报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金地(集团)股份有限公司/2023可持续发展报告.pdf"
        },
        {
            "name": "2023半年报",
            "display": "2023半年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金地(集团)股份有限公司/2023半年报.pdf"
        },
        {
            "name": "2023Q3",
            "display": "2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金地(集团)股份有限公司/2023Q3.pdf"
        },
        {
            "name": "2023Q1",
            "display": "2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金地(集团)股份有限公司/2023Q1.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金地(集团)股份有限公司/2023.pdf"
        }
    ],
    "金科地产集团股份有限公司": [
        {
            "name": "金科股份：2023年半年度报告",
            "display": "金科股份：2023年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金科地产集团股份有限公司/金科股份：2023年半年度报告.pdf"
        },
        {
            "name": "金科股份：2023年三季度报告",
            "display": "金科股份：2023年三季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金科地产集团股份有限公司/金科股份：2023年三季度报告.pdf"
        },
        {
            "name": "金科股份：2023年一季度报告",
            "display": "金科股份：2023年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金科地产集团股份有限公司/金科股份：2023年一季度报告.pdf"
        },
        {
            "name": "_ST金科：金科地产集团股份有限公司2023年年度报告",
            "display": "_ST金科：金科地产集团股份有限公司2023年年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金科地产集团股份有限公司/_ST金科：金科地产集团股份有限公司2023年年度报告.pdf"
        },
        {
            "name": "_ST金科：2024年第一季度报告",
            "display": "_ST金科：2024年第一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金科地产集团股份有限公司/_ST金科：2024年第一季度报告.pdf"
        },
        {
            "name": "_ST金科：2024年半年度报告",
            "display": "_ST金科：2024年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金科地产集团股份有限公司/_ST金科：2024年半年度报告.pdf"
        }
    ],
    "金融街控股股份有限公司": [
        {
            "name": "金 融 街：2024年半年度报告",
            "display": "金 融 街：2024年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金融街控股股份有限公司/金 融 街：2024年半年度报告.pdf"
        },
        {
            "name": "金 融 街：2024年一季度报告",
            "display": "金 融 街：2024年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金融街控股股份有限公司/金 融 街：2024年一季度报告.pdf"
        },
        {
            "name": "金 融 街：2023年年度报告",
            "display": "金 融 街：2023年年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金融街控股股份有限公司/金 融 街：2023年年度报告.pdf"
        },
        {
            "name": "金 融 街：2023年半年度报告",
            "display": "金 融 街：2023年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金融街控股股份有限公司/金 融 街：2023年半年度报告.pdf"
        },
        {
            "name": "金 融 街：2023年三季度报告",
            "display": "金 融 街：2023年三季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金融街控股股份有限公司/金 融 街：2023年三季度报告.pdf"
        },
        {
            "name": "金 融 街：2023年一季度报告",
            "display": "金 融 街：2023年一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金融街控股股份有限公司/金 融 街：2023年一季度报告.pdf"
        }
    ],
    "金陵饭店股份有限公司": [
        {
            "name": "金陵饭店：金陵饭店股份有限公司2024年第一季度报告",
            "display": "金陵饭店：金陵饭店股份有限公司2024年第一季度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金陵饭店股份有限公司/金陵饭店：金陵饭店股份有限公司2024年第一季度报告.PDF"
        },
        {
            "name": "金陵饭店：金陵饭店股份有限公司2024年半年度报告",
            "display": "金陵饭店：金陵饭店股份有限公司2024年半年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金陵饭店股份有限公司/金陵饭店：金陵饭店股份有限公司2024年半年度报告.PDF"
        },
        {
            "name": "金陵饭店：金陵饭店股份有限公司2023年年度报告",
            "display": "金陵饭店：金陵饭店股份有限公司2023年年度报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金陵饭店股份有限公司/金陵饭店：金陵饭店股份有限公司2023年年度报告.PDF"
        },
        {
            "name": "2023半年报",
            "display": "2023半年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金陵饭店股份有限公司/2023半年报.pdf"
        },
        {
            "name": "2023Q3",
            "display": "2023Q3",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金陵饭店股份有限公司/2023Q3.pdf"
        },
        {
            "name": "2023Q1",
            "display": "2023Q1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金陵饭店股份有限公司/2023Q1.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/金陵饭店股份有限公司/2023ESG.pdf"
        }
    ],
    "银河娱乐集团有限公司": [
        {
            "name": "2024H1",
            "display": "2024H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/银河娱乐集团有限公司/2024H1.pdf"
        },
        {
            "name": "2023_1",
            "display": "2023_1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/银河娱乐集团有限公司/2023_1.pdf"
        },
        {
            "name": "2023H1",
            "display": "2023H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/银河娱乐集团有限公司/2023H1.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/银河娱乐集团有限公司/2023.pdf"
        }
    ],
    "雅居乐集团控股有限公司": [
        {
            "name": "2024H",
            "display": "2024H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/雅居乐集团控股有限公司/2024H.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/雅居乐集团控股有限公司/2023H.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/雅居乐集团控股有限公司/2023ESG.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/雅居乐集团控股有限公司/2023.pdf"
        }
    ],
    "雅高酒店集团": [
        {
            "name": "雅高-2024财务半年报",
            "display": "雅高-2024财务半年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/雅高酒店集团/雅高-2024财务半年报.pdf"
        },
        {
            "name": "雅高-2024Q1财务季度报",
            "display": "雅高-2024Q1财务季度报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/雅高酒店集团/雅高-2024Q1财务季度报.pdf"
        },
        {
            "name": "雅高-2023财务年报",
            "display": "雅高-2023财务年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/雅高酒店集团/雅高-2023财务年报.pdf"
        },
        {
            "name": "blockchained_Accor_H1_2024_PR_VDEF",
            "display": "blockchained_Accor_H1_2024_PR_VDEF",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/雅高酒店集团/blockchained_Accor_H1_2024_PR_VDEF.pdf"
        },
        {
            "name": "Interim_Financial_Report_2024_Accor",
            "display": "Interim_Financial_Report_2024_Accor",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/雅高酒店集团/Interim_Financial_Report_2024_Accor.pdf"
        },
        {
            "name": "Interim_Financial_Report_2023_Accor_vDef",
            "display": "Interim_Financial_Report_2023_Accor_vDef",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/雅高酒店集团/Interim_Financial_Report_2023_Accor_vDef.pdf"
        },
        {
            "name": "ACCOR_IMPACT_REPORT_2023",
            "display": "ACCOR_IMPACT_REPORT_2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/雅高酒店集团/ACCOR_IMPACT_REPORT_2023.pdf"
        },
        {
            "name": "ACCOR_DEU2023_MEL_UK",
            "display": "ACCOR_DEU2023_MEL_UK",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/雅高酒店集团/ACCOR_DEU2023_MEL_UK.pdf"
        }
    ],
    "顺豪控股有限公司": [
        {
            "name": "2024H",
            "display": "2024H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/顺豪控股有限公司/2024H.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/顺豪控股有限公司/2023H.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/顺豪控股有限公司/2023ESG.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/顺豪控股有限公司/2023.pdf"
        }
    ],
    "香格里拉（亚洲）有限公司": [
        {
            "name": "香格里拉-可持续发展报告2023",
            "display": "香格里拉-可持续发展报告2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/香格里拉（亚洲）有限公司/香格里拉-可持续发展报告2023.pdf"
        },
        {
            "name": "香格里拉-2024中期报告",
            "display": "香格里拉-2024中期报告",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/香格里拉（亚洲）有限公司/香格里拉-2024中期报告.pdf"
        },
        {
            "name": "香格里拉-2023年报",
            "display": "香格里拉-2023年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/香格里拉（亚洲）有限公司/香格里拉-2023年报.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/香格里拉（亚洲）有限公司/2023H.pdf"
        }
    ],
    "香港上海大酒店有限公司": [
        {
            "name": "半岛-2023年报",
            "display": "半岛-2023年报",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/香港上海大酒店有限公司/半岛-2023年报.pdf"
        },
        {
            "name": "2024H",
            "display": "2024H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/香港上海大酒店有限公司/2024H.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/香港上海大酒店有限公司/2023H.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/香港上海大酒店有限公司/2023ESG.pdf"
        }
    ],
    "香港中旅国际投资有限公司": [
        {
            "name": "2024H",
            "display": "2024H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/香港中旅国际投资有限公司/2024H.pdf"
        },
        {
            "name": "2023H",
            "display": "2023H",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/香港中旅国际投资有限公司/2023H.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/香港中旅国际投资有限公司/2023.pdf"
        }
    ],
    "龙湖集团控股有限公司": [
        {
            "name": "2024H1",
            "display": "2024H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/龙湖集团控股有限公司/2024H1.pdf"
        },
        {
            "name": "2023H1",
            "display": "2023H1",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/龙湖集团控股有限公司/2023H1.pdf"
        },
        {
            "name": "2023ESG",
            "display": "2023ESG",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/龙湖集团控股有限公司/2023ESG.pdf"
        },
        {
            "name": "2023",
            "display": "2023",
            "type": "FILE",
            "external_url": "https://oss.datawang.com/内容文件/上市公司报告（釜底抽薪）/龙湖集团控股有限公司/2023.pdf"
        }
    ]
}