export default [
    {
        "1.集团名称": "万豪国际酒店集团",
        "2.集团分类【筛选】": "国际上市公司",
        "3.上市交易所": "纳斯达克",
        "4.股票代码": "MAR",
        "5.市值（亿美元）": 737.3
    },
    {
        "1.集团名称": "希尔顿酒店集团",
        "2.集团分类【筛选】": "国际上市公司",
        "3.上市交易所": "纽约证券交易所",
        "4.股票代码": "HLT",
        "5.市值（亿美元）": 584
    },
    {
        "1.集团名称": "丽呈酒店集团",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 9961,
        "5.市值（亿美元）": 442.59,
        "6.备注": "上市公司为其母公司携程集团有限公司"
    },
    {
        "1.集团名称": "中信股份",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 267,
        "5.市值（亿美元）": 347.4013545
    },
    {
        "1.集团名称": "新鸿基地产发展有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 16,
        "5.市值（亿美元）": 315.6661539
    },
    {
        "1.集团名称": "木棉花酒店管理集团",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 1109,
        "5.市值（亿美元）": 235.375839,
        "6.备注": "上市公司为其母公司华润置地有限公司"
    },
    {
        "1.集团名称": "银河娱乐集团有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 27,
        "5.市值（亿美元）": 218.6588781
    },
    {
        "1.集团名称": "中国旅游集团中免股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 601888,
        "5.市值（亿美元）": 208.97
    },
    {
        "1.集团名称": "中国海外发展有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 688,
        "5.市值（亿美元）": 207.8874414
    },
    {
        "1.集团名称": "中国中铁股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 601390,
        "5.市值（亿美元）": 179.580315341188
    },
    {
        "1.集团名称": "洲际酒店集团",
        "2.集团分类【筛选】": "国际上市公司",
        "3.上市交易所": "伦敦证券交易所",
        "4.股票代码": "IHG",
        "5.市值（亿美元）": 172.740856161
    },
    {
        "1.集团名称": "保利酒店集团",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 600048,
        "5.市值（亿美元）": 165.848570948265,
        "6.备注": "上市公司为其母公司保利发展控股集团有限公司"
    },
    {
        "1.集团名称": "凯悦酒店集团",
        "2.集团分类【筛选】": "国际上市公司",
        "3.上市交易所": "纽约证券交易所",
        "4.股票代码": "H",
        "5.市值（亿美元）": 154.5
    },
    {
        "1.集团名称": "华住集团有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 1179,
        "5.市值（亿美元）": 134.4692259
    },
    {
        "1.集团名称": "招商局蛇口工业区控股股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": 1979,
        "5.市值（亿美元）": 132.925666199158
    },
    {
        "1.集团名称": "万科企业股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": 2,
        "5.市值（亿美元）": 124.382889200561
    },
    {
        "1.集团名称": "太古酒店集团",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 1972,
        "5.市值（亿美元）": 123.0028578,
        "6.备注": "上市公司为其母公司太古地产有限公司"
    },
    {
        "1.集团名称": "龙湖集团控股有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 960,
        "5.市值（亿美元）": 118.6917093
    },
    {
        "1.集团名称": "雅高酒店集团",
        "2.集团分类【筛选】": "国际上市公司",
        "3.上市交易所": "泛欧证券交易所",
        "4.股票代码": "AC",
        "5.市值（亿美元）": 104.527368
    },
    {
        "1.集团名称": "九龙仓酒店管理有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 4,
        "5.市值（亿美元）": 88.282026,
        "6.备注": "上市公司为其母公司九龙仓集团有限公司"
    },
    {
        "1.集团名称": "温德姆酒店集团",
        "2.集团分类【筛选】": "国际上市公司",
        "3.上市交易所": "纽约证券交易所",
        "4.股票代码": "WH",
        "5.市值（亿美元）": 62.6
    },
    {
        "1.集团名称": "新城控股集团股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 601155,
        "5.市值（亿美元）": 42.5636914951739
    },
    {
        "1.集团名称": "绿地酒店旅游集团",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 600606,
        "5.市值（亿美元）": 40.558241738229,
        "6.备注": "上市公司为其母公司绿地控股集团股份有限公司"
    },
    {
        "1.集团名称": "厦门建发股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 600153,
        "5.市值（亿美元）": 38.4369616015018
    },
    {
        "1.集团名称": "亚朵集团",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "纳斯达克",
        "4.股票代码": "ATAT",
        "5.市值（亿美元）": 38.3
    },
    {
        "1.集团名称": "上海锦江国际酒店股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 600754,
        "5.市值（亿美元）": 35.1875529187622
    },
    {
        "1.集团名称": "上海豫园旅游商城（集团）股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 600655,
        "5.市值（亿美元）": 32.7
    },
    {
        "1.集团名称": "金地(集团)股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 600383,
        "5.市值（亿美元）": 32.3179497919673
    },
    {
        "1.集团名称": "嘉里建设有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 683,
        "5.市值（亿美元）": 31.6322478
    },
    {
        "1.集团名称": "深圳华侨城股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": 69,
        "5.市值（亿美元）": 30.6633941093969
    },
    {
        "1.集团名称": "尊蓝酒店管理有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 3900,
        "5.市值（亿美元）": 28.0803762,
        "6.备注": "上市公司为其母公司绿城中国控股有限公司"
    },
    {
        "1.集团名称": "永乐华住酒店管理有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 1918,
        "5.市值（亿美元）": 27.8616015,
        "6.备注": "上市公司为其母公司融创中国控股有限公司"
    },
    {
        "1.集团名称": "香格里拉（亚洲）有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 69,
        "5.市值（亿美元）": 26.8449426
    },
    {
        "1.集团名称": "珠海华发实业股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 600325,
        "5.市值（亿美元）": 24.9063540338736
    },
    {
        "1.集团名称": "中国中铁股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 390,
        "5.市值（亿美元）": 22.7396997
    },
    {
        "1.集团名称": "北京首旅酒店（集团）股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 600258,
        "5.市值（亿美元）": 20.7105689029601
    },
    {
        "1.集团名称": "中国金茂控股集团有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 817,
        "5.市值（亿美元）": 19.6253775
    },
    {
        "1.集团名称": "金光上海白玉兰广场资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "白玉兰2019-1",
        "5.市值（亿美元）": 19.458
    },
    {
        "1.集团名称": "大悦城控股集团股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": 31,
        "5.市值（亿美元）": 18.5161290322581
    },
    {
        "1.集团名称": "凤悦酒店管理（广东）有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 2007,
        "5.市值（亿美元）": 17.4633687,
        "6.备注": "上市公司为其母公司碧桂园集团"
    },
    {
        "1.集团名称": "上海城投控股股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 600649,
        "5.市值（亿美元）": 15.9465004272726
    },
    {
        "1.集团名称": "金融街控股股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": 402,
        "5.市值（亿美元）": 13.4558204768583
    },
    {
        "1.集团名称": "新华联文化旅游发展股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": 620,
        "5.市值（亿美元）": 13.4235624123422
    },
    {
        "1.集团名称": "香港上海大酒店有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 45,
        "5.市值（亿美元）": 11.839572
    },
    {
        "1.集团名称": "中国光大控股",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 165,
        "5.市值（亿美元）": 11.3762844
    },
    {
        "1.集团名称": "荣盛房地产发展股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": 2146,
        "5.市值（亿美元）": 10.93
    },
    {
        "1.集团名称": "北京银泰中心资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "银泰2016-1",
        "5.市值（亿美元）": 10.575
    },
    {
        "1.集团名称": "中青旅控股股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 600138,
        "5.市值（亿美元）": 10.5154179566563
    },
    {
        "1.集团名称": "三亚亚特兰蒂斯资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "复星2020-1",
        "5.市值（亿美元）": 9.87141
    },
    {
        "1.集团名称": "苏宁环球股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": 718,
        "5.市值（亿美元）": 9.44880785413745
    },
    {
        "1.集团名称": "广州岭南集团控股股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": 524,
        "5.市值（亿美元）": 8.88218793828892
    },
    {
        "1.集团名称": "金科地产集团股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": 656,
        "5.市值（亿美元）": 8.53716690042076
    },
    {
        "1.集团名称": "广州富力地产股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 2777,
        "5.市值（亿美元）": 8.3906532
    },
    {
        "1.集团名称": "西域旅游开发股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": 300859,
        "5.市值（亿美元）": 8.25
    },
    {
        "1.集团名称": "世茂喜达酒店管理集团",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 813,
        "5.市值（亿美元）": 8.2490931,
        "6.备注": "上市公司为其母公司世茂集团控股有限公司"
    },
    {
        "1.集团名称": "彩云之南酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "恒浩云2015-1",
        "5.市值（亿美元）": 8.178
    },
    {
        "1.集团名称": "华夏幸福基业股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 600340,
        "5.市值（亿美元）": 8.16923259039267
    },
    {
        "1.集团名称": "香港中旅国际投资有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 308,
        "5.市值（亿美元）": 7.978842
    },
    {
        "1.集团名称": "浙江祥源文旅股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 600576,
        "5.市值（亿美元）": 7.94
    },
    {
        "1.集团名称": "云南旅游股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": 2059,
        "5.市值（亿美元）": 7.7
    },
    {
        "1.集团名称": "嘉华国际集团有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 173,
        "5.市值（亿美元）": 7.6571145
    },
    {
        "1.集团名称": "瑞安房地产有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 272,
        "5.市值（亿美元）": 7.6442454
    },
    {
        "1.集团名称": "越秀房产信托基金",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 405,
        "5.市值（亿美元）": 7.2967797
    },
    {
        "1.集团名称": "雅居乐集团控股有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 3383,
        "5.市值（亿美元）": 7.078005
    },
    {
        "1.集团名称": "上海万象城资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "万象城2021-1",
        "5.市值（亿美元）": 7.05141
    },
    {
        "1.集团名称": "丽江玉龙旅游股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": 2033,
        "5.市值（亿美元）": 6.87377279102384
    },
    {
        "1.集团名称": "上海鲁能JW万豪侯爵酒店资产支持专项计划（碳中和）",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "侯爵2021-1",
        "5.市值（亿美元）": 6.345
    },
    {
        "1.集团名称": "君亭酒店集团股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": 301073,
        "5.市值（亿美元）": 5.69144460028051
    },
    {
        "1.集团名称": "云南城投置业股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 600239,
        "5.市值（亿美元）": 5.62349200790104
    },
    {
        "1.集团名称": "安徽九华山旅游发展股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 603199,
        "5.市值（亿美元）": 5.41
    },
    {
        "1.集团名称": "汇贤产业信托",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 87001,
        "5.市值（亿美元）": 5.3792838
    },
    {
        "1.集团名称": "华天酒店集团股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": 428,
        "5.市值（亿美元）": 4.65918653576438
    },
    {
        "1.集团名称": "迈科商业中心综合体资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "迈科2019-1",
        "5.市值（亿美元）": 4.653
    },
    {
        "1.集团名称": "深业上城智慧城市运营资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": "上城2022-1",
        "5.市值（亿美元）": 4.512
    },
    {
        "1.集团名称": "三亚山海天JW万豪酒店及傲途格精选酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "傲途格2020-1",
        "5.市值（亿美元）": 4.2723
    },
    {
        "1.集团名称": "2023金融街威斯汀酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "威斯汀2023-1",
        "5.市值（亿美元）": 4.23141
    },
    {
        "1.集团名称": "鑫悦绿色资产支持专项计划(碳中和)",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "鑫悦2023-1",
        "5.市值（亿美元）": 4.23
    },
    {
        "1.集团名称": "Hotel Grand（大中酒店有限公司）",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "新加坡证券交易所",
        "4.股票代码": "H18",
        "5.市值（亿美元）": 4.05062048
    },
    {
        "1.集团名称": "广州海航双塔资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "双塔2017-1",
        "5.市值（亿美元）": 3.807
    },
    {
        "1.集团名称": "广州珠江发展集团股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 600684,
        "5.市值（亿美元）": 3.77812070100724
    },
    {
        "1.集团名称": "金陵饭店股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 601007,
        "5.市值（亿美元）": 3.60043707885631
    },
    {
        "1.集团名称": "西安曲江文化旅游股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 600706,
        "5.市值（亿美元）": 3.43019192244652
    },
    {
        "1.集团名称": "宝龙酒店集团",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 1238,
        "5.市值（亿美元）": 3.345966,
        "6.备注": "上市公司为其母公司宝龙地产控股有限公司"
    },
    {
        "1.集团名称": "GreenTree Hospitality Group",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "纽约证券交易所",
        "4.股票代码": "GHG",
        "5.市值（亿美元）": 3.2
    },
    {
        "1.集团名称": "国旅文化投资集团股份有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": 600358,
        "5.市值（亿美元）": 3.01
    },
    {
        "1.集团名称": "中能建城市发展公司海南嘉佩乐酒店（海南自贸区）资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "嘉佩乐2023-1",
        "5.市值（亿美元）": 2.96241
    },
    {
        "1.集团名称": "富豪酒店国际控股有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 78,
        "5.市值（亿美元）": 2.9084166
    },
    {
        "1.集团名称": "杭州国际会议中心二期资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "杭国际2023-2",
        "5.市值（亿美元）": 2.82141
    },
    {
        "1.集团名称": "北京保利大厦资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "保利2020-1",
        "5.市值（亿美元）": 2.82
    },
    {
        "1.集团名称": "中国恒大集团",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 3333,
        "5.市值（亿美元）": 2.7668565
    },
    {
        "1.集团名称": "凯联国际酒店有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 105,
        "5.市值（亿美元）": 2.5866891
    },
    {
        "1.集团名称": "北控雁栖湖会展中心资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": "雁栖湖2021-1",
        "5.市值（亿美元）": 2.53941
    },
    {
        "1.集团名称": "北京北大科技园建设开发有限公司2018年第一期资产支持票据",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "银行间债券市场",
        "4.股票代码": "北大科技ABN2018-1",
        "5.市值（亿美元）": 2.52531
    },
    {
        "1.集团名称": "卓越四季酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": "四季2021-1",
        "5.市值（亿美元）": 2.5239
    },
    {
        "1.集团名称": "仁恒皇冠假日酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "皇冠2020-1",
        "5.市值（亿美元）": 2.4675
    },
    {
        "1.集团名称": "朗廷酒店投资有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 1270,
        "5.市值（亿美元）": 2.4193908
    },
    {
        "1.集团名称": "杭州黄龙饭店绿色资产支持专项计划（碳中和）",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "5.市值（亿美元）": 2.35611
    },
    {
        "1.集团名称": "渔阳饭店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": "渔阳2018-1",
        "5.市值（亿美元）": 2.256
    },
    {
        "1.集团名称": "富豪产业信托",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 1881,
        "5.市值（亿美元）": 2.2520925
    },
    {
        "1.集团名称": "恒泰商置资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "苏恒2021-1",
        "5.市值（亿美元）": 2.11641
    },
    {
        "1.集团名称": "济南贵和商业物业资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": "贵和2019-1",
        "5.市值（亿美元）": 2.09949
    },
    {
        "1.集团名称": "信和酒店（集团）有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 1221,
        "5.市值（亿美元）": 2.059056
    },
    {
        "1.集团名称": "合肥融创酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "融华2021-1",
        "5.市值（亿美元）": 1.97541
    },
    {
        "1.集团名称": "金茂酒店鑫选1号资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "金茂2021-1",
        "5.市值（亿美元）": 1.94721
    },
    {
        "1.集团名称": "上海宏安瑞士大酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "新静安2023-1",
        "5.市值（亿美元）": 1.80621
    },
    {
        "1.集团名称": "华宇集团商业物业资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "华宇2021-1",
        "5.市值（亿美元）": 1.79352
    },
    {
        "1.集团名称": "浙旅投望湖浙宾资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "浙旅投2023-1",
        "5.市值（亿美元）": 1.7202
    },
    {
        "1.集团名称": "华发瑞吉酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "瑞吉2021-1",
        "5.市值（亿美元）": 1.692
    },
    {
        "1.集团名称": "万达酒店发展有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 169,
        "5.市值（亿美元）": 1.6601139
    },
    {
        "1.集团名称": "Hotel Royal（京华大酒店）",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "新加坡证券交易所",
        "4.股票代码": "H12",
        "5.市值（亿美元）": 1.59031132
    },
    {
        "1.集团名称": "中铁置业青岛中心资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "青岛中心2022-1",
        "5.市值（亿美元）": 1.58766
    },
    {
        "1.集团名称": "2022金融街丽思卡尔顿酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "5.市值（亿美元）": 1.48191
    },
    {
        "1.集团名称": "北辰长沙洲际酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "北辰2018-1",
        "5.市值（亿美元）": 1.4805
    },
    {
        "1.集团名称": "保利凯悦假日酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "保置2022-1",
        "5.市值（亿美元）": 1.47486
    },
    {
        "1.集团名称": "安徽高速开元国际大酒店及安徽高速徽风皖韵酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "安高2023-1",
        "5.市值（亿美元）": 1.34091
    },
    {
        "1.集团名称": "运达喜来登酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "喜来登2021-1",
        "5.市值（亿美元）": 1.3395
    },
    {
        "1.集团名称": "光控安石绿城尊蓝酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "尊蓝2020-1",
        "5.市值（亿美元）": 1.33668
    },
    {
        "1.集团名称": "宝龙地产商业一号资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "宝龙2019-1",
        "5.市值（亿美元）": 1.269
    },
    {
        "1.集团名称": "西安阳光天地二期资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": "阳光天地2021-2",
        "5.市值（亿美元）": 1.1985
    },
    {
        "1.集团名称": "百利保控股有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 617,
        "5.市值（亿美元）": 1.0901157228
    },
    {
        "1.集团名称": "南京世茂希尔顿酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "希尔顿2020-1",
        "5.市值（亿美元）": 1.0011
    },
    {
        "1.集团名称": "宁波象山海景皇冠假日酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "甬城投2023-1",
        "5.市值（亿美元）": 0.96021
    },
    {
        "1.集团名称": "中国诚通发展集团有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 217,
        "5.市值（亿美元）": 0.9364586688
    },
    {
        "1.集团名称": "宜宾皇冠假日酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": "美丽汇2021-1",
        "5.市值（亿美元）": 0.92073
    },
    {
        "1.集团名称": "杭州西溪投资资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "5.市值（亿美元）": 0.91791
    },
    {
        "1.集团名称": "世茂酒店物业权益型房托资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": "世茂酒2020-1",
        "5.市值（亿美元）": 0.9165
    },
    {
        "1.集团名称": "阳光城集团股份有限公司2020年度第一期资产支持票据",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "银行间债券市场",
        "4.股票代码": "阳光城ABN2020-1",
        "5.市值（亿美元）": 0.846
    },
    {
        "1.集团名称": "大中华酒店（香港）有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 21,
        "5.市值（亿美元）": 0.8440971381,
        "6.备注": "上市公司为其母公司大中华控股(香港)有限公司"
    },
    {
        "1.集团名称": "华大酒店投资有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 201,
        "5.市值（亿美元）": 0.8174967084
    },
    {
        "1.集团名称": "扬州迎宾馆资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": "迎宾馆2022-1",
        "5.市值（亿美元）": 0.8037
    },
    {
        "1.集团名称": "扬州虹桥坊酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "虹桥坊2019-1",
        "5.市值（亿美元）": 0.79665
    },
    {
        "1.集团名称": "太湖新城君来世尊酒店资产支持专项计划（长三角一体化）",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "君来2022-1",
        "5.市值（亿美元）": 0.7191
    },
    {
        "1.集团名称": "深圳东华假日酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "深圳证券交易所",
        "4.股票代码": "东华2021-1",
        "5.市值（亿美元）": 0.705
    },
    {
        "1.集团名称": "英皇娱乐酒店有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 296,
        "5.市值（亿美元）": 0.5429601981
    },
    {
        "1.集团名称": "鲁商酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "5.市值（亿美元）": 0.51888
    },
    {
        "1.集团名称": "宁波钱湖酒店资产支持专项计划",
        "2.集团分类【筛选】": "国内上市REITs",
        "3.上市交易所": "上海证券交易所",
        "4.股票代码": "甬钱湖2023-1",
        "5.市值（亿美元）": 0.39621
    },
    {
        "1.集团名称": "开源控股有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 1215,
        "5.市值（亿美元）": 0.3124488789
    },
    {
        "1.集团名称": "顺豪控股有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 253,
        "5.市值（亿美元）": 0.2898507393
    },
    {
        "1.集团名称": "海天地悦旅集团有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 1832,
        "5.市值（亿美元）": 0.27797256
    },
    {
        "1.集团名称": "泛海酒店集团有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 292,
        "5.市值（亿美元）": 0.2077587504
    },
    {
        "1.集团名称": "新都酒店集团",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 8315,
        "5.市值（亿美元）": 0.169099974
    },
    {
        "1.集团名称": "中国生态旅游集团有限公司",
        "2.集团分类【筛选】": "国内上市公司",
        "3.上市交易所": "香港交易所",
        "4.股票代码": 1371,
        "5.市值（亿美元）": 0.049674726
    }
]