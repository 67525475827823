<template>
    <NuxtPage />
    <Modal style="height:80vh"/>
    <!-- <PWAPromptWrapper/> -->
    <Watermark v-if="route.path != '/login'" style="top:50px" :text="getWatermarkText()" />
</template>

<script setup lang="ts">
import version from './version'
import tscroll from './utils/tscroll'
import {useIsMobileStore} from '~/stores/isMobileStore'

import {useUserInfoStore} from '@/stores/userInfoStore'

// import PWAPromptWrapper from './components/PWAPrompt/Wrapper.vue'

// import {afterLogin} from '~/pages/loginUtils/afterLogin'

// init tFetch
onBeforeMount(async ()=>{
    try{
        // await tFetch('/api/ping',process.server ? {useFetch} :{useAsyncData})
        // console.log("tFetch inited")
    }catch(e){
        console.log("tFetch init err", e)
    }
})

const nav = ref('')

const route = useRoute()

const userInfoStore = useUserInfoStore()
const getWatermarkText = () => {
    if(userInfoStore.info){
        const phoneLast4 = userInfoStore.info.phone_number ? userInfoStore.info.phone_number.slice(-4) : ''
        return userInfoStore.info.name + ' ' + phoneLast4
    }
    else{
        return ''
    }
}

const init = async ()=>{
    console.log('version', version)
    try{

        const isMobileStore = useIsMobileStore()
        
        if(process.client){
            if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                isMobileStore.value = true
            }
            else{
                isMobileStore.value = false
            }
        }

        if(process.env.NODE_ENV == 'production'){
            // console.log = () => {}
        }
        
        if(process.client){
            nav.value = navigator.userAgent
        }
    }catch(e){
        console.log('mount err', e)
    }

    if(process.client){
        window.addEventListener('scroll', () => {
            tscroll.value = document.documentElement.scrollTop
        })
    }
    // afterLogin()
}
await init()
</script>

<style scoped>
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}

.version_display{
    color: #636363;
    position: absolute;
    right: 10px;
}
</style>

<style>

.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input{
    border-radius: 15px;
    border-color: black;
    background-color: rgba(237, 246, 250, 1);
}
.anticon{
    color:black
}

.grayoutlesshover {
    filter: grayscale(100%);
    transition: filter 0.1s ease-in-out;
}
.grayoutlesshover:hover {
    filter: grayscale(0%);
}
</style>

